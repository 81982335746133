import React from "react";

const Meta = (metaObject) => {
  switch (metaObject.type) {
    case "cover":
      return (
        <div className="meta-section">
          <img src={metaObject.coverURL} className={"meta-section__cover"}/>
        </div>
      );
    case "links":
      return (
        <div className="meta-section">
          <div className="meta-section__title">
            {metaObject.title || "Links"}
          </div>
          <div className="meta-section__content">{metaObject.content}</div>
        </div>
      );
    default:
      return (
        <div className="meta-section">
          <div className="meta-section__title">{metaObject.title}</div>
          <div className="meta-section__content">{metaObject.content}</div>
        </div>
      );
  }
};

export default Meta;
