import React from "react";
import FormBuilder from "components/FeedbackForms/FormBuilder.jsx";
import { useParams } from "react-router";

const FormsPage = () => {
  const params = useParams();

  return <FormBuilder formId={params?.formId} />;
};

export default FormsPage;
