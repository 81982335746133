import Box from "components/shared/Box";
import React from "react";

const TOSPage = () => (
  <div style={{ paddingTop: "60px" }}>
    <Box
      title={{ type: "accent", text: "Terms & Conditions" }}
      className="container tos-page"
    >
      <p>Updated at 2024-11-25</p>

      <h2>General Terms</h2>
      <p>
        By accessing and placing an order with CentralFeedback, you confirm that
        you are in agreement with and bound by the terms of service contained in
        the Terms & Conditions outlined below. These terms apply to the entire
        website and any email or other type of communication between you and
        CentralFeedback.
      </p>
      <p>
        Under no circumstances shall CentralFeedback team be liable for any
        direct, indirect, special, incidental or consequential damages,
        including, but not limited to, loss of data or profit, arising out of
        the use, or the inability to use, the materials on this site, even if
        CentralFeedback team or an authorized representative has been advised of
        the possibility of such damages. If your use of materials from this site
        results in the need for servicing, repair or correction of equipment or
        data, you assume any costs thereof.
      </p>
      <p>
        CentralFeedback will not be responsible for any outcome that may occur
        during the course of usage of our resources. We reserve the rights to
        change prices and revise the resources usage policy in any moment. This
      </p>

      <h2>License</h2>
      <p>
        CentralFeedback grants you a revocable, non-exclusive, non-transferable,
        limited license to download, install and use the website strictly in
        accordance with the terms of this Agreement.
      </p>
      <p>
        These Terms & Conditions are a contract between you and CentralFeedback
        (referred to in these Terms & Conditions as "CentralFeedback", "us",
        "we" or "our"), the provider of the CentralFeedback website and the
        services accessible from the CentralFeedback website (which are
        collectively referred to in these Terms & Conditions as the
        "CentralFeedback Service").
      </p>
      <p>
        You are agreeing to be bound by these Terms & Conditions. If you do not
        agree to these Terms & Conditions, please do not use the CentralFeedback
        Service. In these Terms & Conditions, "you" refers both to you as an
        individual and to the entity you represent. If you violate any of these
        Terms & Conditions, we reserve the right to cancel your account or block
        access to your account without notice.
      </p>

      <h2>Definitions and key terms</h2>
      <p>
        To help explain things as clearly as possible in this Terms &
        Conditions, every time any of these terms are referenced, are strictly
        defined as:
      </p>
      <ul>
        <li>
          Cookie: small amount of data generated by a website and saved by your
          web browser. It is used to identify your browser, provide analytics,
          remember information about you such as your language preference or
          login information.
        </li>
        <li>
          Company: when this policy mentions “Company,” “we,” “us,” or “our,” it
          refers to SC Aza Softserve SRL, that is responsible for your
          information under this Terms & Conditions.
        </li>
        <li>
          Country: where CentralFeedback or the owners/founders of
          CentralFeedback are based, in this case is{" "}
        </li>
        <li>
          Device: any internet connected device such as a phone, tablet,
          computer or any other device that can be used to visit CentralFeedback
          and use the services.
        </li>
        <li>
          Service: refers to the service provided by CentralFeedback as
          described in the relative terms (if available) and on this platform.
        </li>
        <li>
          Third-party service: refers to advertisers, contest sponsors,
          promotional and marketing partners, and others who provide our content
          or whose products or services we think may interest you.
        </li>
        <li>
          Website: CentralFeedback's site, which can be accessed via this URL:
          centralfeedback.com
        </li>
        <li>
          You: a person or entity that is registered with CentralFeedback to use
          the Services.
        </li>
      </ul>

      <h2>Restrictions</h2>
      <p>You agree not to, and you will not permit others to:</p>
      <ul>
        <li>
          License, sell, rent, lease, assign, distribute, transmit, host,
          outsource, disclose or otherwise commercially exploit the website or
          make the platform available to any third party.
        </li>
        <li>
          Modify, make derivative works of, disassemble, decrypt, reverse
          compile or reverse engineer any part of the website.
        </li>
        <li>
          Remove, alter or obscure any proprietary notice (including any notice
          of copyright or trademark) of CentralFeedback or its affiliates,
          partners, suppliers or the licensors of the website.
        </li>
      </ul>
      <h2>Return and Refund Policy</h2>
      <p>
        Thanks for shopping at CentralFeedback. We appreciate the fact that you
        like to buy the stuff we build. We also want to make sure you have a
        rewarding experience while you’re exploring, evaluating, and purchasing
        our products.
      </p>
      <p>
        As with any shopping experience, there are terms and conditions that
        apply to transactions at CentralFeedback. We’ll be as brief as our
        attorneys will allow. The main thing to remember is that by placing an
        order or making a purchase at CentralFeedback, you agree to the terms
        along with CentralFeedback's Privacy Policy.
      </p>
      <p>
        If, for any reason, You are not completely satisfied with any good or
        service that we provide, don't hesitate to contact us and we will
        discuss any of the issues you are going through with our product.
      </p>

      <h2>Your Suggestions</h2>
      <p>
        Any feedback, comments, ideas, improvements or suggestions
        (collectively, "Suggestions") provided by you to CentralFeedback with
        respect to the website shall remain the sole and exclusive property of
        CentralFeedback.
      </p>
      <p>
        CentralFeedback shall be free to use, copy, modify, publish, or
        redistribute the Suggestions for any purpose and in any way without any
        credit or any compensation to you.
      </p>

      <h2>Your Consent</h2>
      <p>
        We've updated our Terms & Conditions to provide you with complete
        transparency into what is being set when you visit our site and how it's
        being used. By using our website, registering an account, or making a
        purchase, you hereby consent to our Terms & Conditions.
      </p>

      <h2>Links to Other Websites</h2>
      <p>
        This Terms & Conditions applies only to the Services. The Services may
        contain links to other websites not operated or controlled by
        CentralFeedback. We are not responsible for the content, accuracy or
        opinions expressed in such websites, and such websites are not
        investigated, monitored or checked for accuracy or completeness by us.
        Please remember that when you use a link to go from the Services to
        another website, our Terms & Conditions are no longer in effect. Your
        browsing and interaction on any other website, including those that have
        a link on our platform, is subject to that website’s own rules and
        policies. Such third parties may use their own cookies or other methods
        to collect information about you.
      </p>

      <h2>Cookies</h2>
      <p>
        CentralFeedback uses "Cookies" to identify the areas of our website that
        you have visited. A Cookie is a small piece of data stored on your
        computer or mobile device by your web browser. We use Cookies to enhance
        the performance and functionality of our website but are non-essential
        to their use. However, without these cookies, certain functionality like
        videos may become unavailable or you would be required to enter your
        login details every time you visit the website as we would not be able
        to remember that you had logged in previously. Most web browsers can be
        set to disable the use of Cookies. However, if you disable Cookies, you
        may not be able to access functionality on our website correctly or at
        all. We never place Personally Identifiable Information in Cookies.
      </p>

      <h2>Changes To Our Terms & Conditions</h2>
      <p>
        You acknowledge and agree that CentralFeedback may stop (permanently or
        temporarily) providing the Service (or any features within the Service)
        to you or to users generally at sole discretion, without prior notice to
        you. You may stop using the Service at any time. You do not need to
        specifically inform CentralFeedback when you stop using the Service. You
        acknowledge and agree that if CentralFeedback disables access to your
        account, you may be prevented from accessing the Service, your account
        details or any files or other materials which is contained in your
        account.
      </p>
      <p>
        If we decide to change our Terms & Conditions, we will post those
        changes on this page, and/or update the Terms & Conditions modification
        date below.
      </p>

      <h2>Modifications to Our website</h2>
      <p>
        CentralFeedback reserves the right to modify, suspend or discontinue,
        temporarily or permanently, the website or any service to which it
        connects, with or without notice and without liability to you.
      </p>

      <h2>Updates to Our website</h2>
      <p>
        CentralFeedback may from time to time provide enhancements or
        improvements to the features/ functionality of the website, which may
        include patches, bug fixes, updates, upgrades and other modifications
        ("Updates").
      </p>
      <p>
        Updates may modify or delete certain features and/or functionalities of
        the website. You agree that CentralFeedback has no obligation to (i)
        provide any Updates, or (ii) continue to provide or enable any
        particular features and/or functionalities of the website to you.
      </p>
      <p>
        You further agree that all Updates will be (i) deemed to constitute an
        integral part of the website, and (ii) subject to the terms and
        conditions of this Agreement.
      </p>

      <h2>Third-Party Services</h2>
      <p>
        We may display, include or make available third-party content (including
        data, information, applications and other products services) or provide
        links to third-party websites or services ("Third- Party Services").
      </p>
      <p>
        You acknowledge and agree that CentralFeedback shall not be responsible
        for any Third-Party Services, including their accuracy, completeness,
        timeliness, validity, copyright compliance, legality, decency, quality
        or any other aspect thereof. CentralFeedback does not assume and shall
        not have any liability or responsibility to you or any other person or
        entity for any Third-Party Services.
      </p>
      <p>
        Third-Party Services and links thereto are provided solely as a
        convenience to you and you access and use them entirely at your own risk
        and subject to such third parties' terms and conditions.
      </p>

      <h2>Term and Termination</h2>
      <p>
        This Agreement shall remain in effect until terminated by you or
        CentralFeedback.
      </p>
      <p>
        CentralFeedback may, in its sole discretion, at any time and for any or
        no reason, suspend or terminate this Agreement with or without prior
        notice.
      </p>
      <p>
        This Agreement will terminate immediately, without prior notice from
        CentralFeedback, in the event that you fail to comply with any provision
        of this Agreement. You may also terminate this Agreement by deleting the
        website and all copies thereof from your computer.
      </p>
      <p>
        Upon termination of this Agreement, you shall cease all use of the
        website and delete all copies of the website from your computer.
      </p>
      <p>
        Termination of this Agreement will not limit any of CentralFeedback's
        rights or remedies at law or in equity in case of breach by you (during
        the term of this Agreement) of any of your obligations under the present
        Agreement.
      </p>

      <h2>Copyright Infringement Notice</h2>
      <p>
        If you are a copyright owner or such owner’s agent and believe any
        material on our website constitutes an infringement on your copyright,
        please contact us setting forth the following information: (a) a
        physical or electronic signature of the copyright owner or a person
        authorized to act on his behalf; (b) identification of the material that
        is claimed to be infringing; (c) your contact information, including
        your address, telephone number, and an email; (d) a statement by you
        that you have a good faith belief that use of the material is not
        authorized by the copyright owners; and (e) the a statement that the
        information in the notification is accurate, and, under penalty of
        perjury you are authorized to act on behalf of the owner.
      </p>

      <h2>Indemnification</h2>
      <p>
        You agree to indemnify and hold CentralFeedback and its parents,
        subsidiaries, affiliates, officers, employees, agents, partners and
        licensors (if any) harmless from any claim or demand, including
        reasonable attorneys' fees, due to or arising out of your: (a) use of
        the website; (b) violation of this Agreement or any law or regulation;
        or (c) violation of any right of a third party.
      </p>

      <h2>No Warranties</h2>
      <p>
        The website is provided to you "AS IS" and "AS AVAILABLE" and with all
        faults and defects without warranty of any kind. To the maximum extent
        permitted under applicable law, CentralFeedback, on its own behalf and
        on behalf of its affiliates and its and their respective licensors and
        service providers, expressly disclaims all warranties, whether express,
        implied, statutory or otherwise, with respect to the website, including
        all implied warranties of merchantability, fitness for a particular
        purpose, title and non-infringement, and warranties that may arise out
        of course of dealing, course of performance, usage or trade practice.
        Without limitation to the foregoing, CentralFeedback provides no
        warranty or undertaking, and makes no representation of any kind that
        the website will meet your requirements, achieve any intended results,
        be compatible or work with any other software, , systems or services,
        operate without interruption, meet any performance or reliability
        standards or be error free or that any errors or defects can or will be
        corrected.
      </p>
      <p>
        Without limiting the foregoing, neither CentralFeedback nor any
        CentralFeedback's provider makes any representation or warranty of any
        kind, express or implied: (i) as to the operation or availability of the
        website, or the information, content, and materials or products included
        thereon; (ii) that the website will be uninterrupted or error-free;
        (iii) as to the accuracy, reliability, or currency of any information or
        content provided through the website; or (iv) that the website, its
        servers, the content, or e-mails sent from or on behalf of
        CentralFeedback are free of viruses, scripts, trojan horses, worms,
        malware, timebombs or other harmful components.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of or limitations on
        implied warranties or the limitations on the applicable statutory rights
        of a consumer, so some or all of the above exclusions and limitations
        may not apply to you.
      </p>

      <h2>Limitation of Liability</h2>
      <p>
        Notwithstanding any damages that you might incur, the entire liability
        of CentralFeedback and any of its suppliers under any provision of this
        Agreement and your exclusive remedy for all of the foregoing shall be
        limited to the amount actually paid by you for the website.
      </p>
      <p>
        To the maximum extent permitted by applicable law, in no event shall
        CentralFeedback or its suppliers be liable for any special, incidental,
        indirect, or consequential damages whatsoever (including, but not
        limited to, damages for loss of profits, for loss of data or other
        information, for business interruption, for personal injury, for loss of
        privacy arising out of or in any way related to the use of or inability
        to use the website, third-party software and/or third-party hardware
        used with the website, or otherwise in connection with any provision of
        this Agreement), even if CentralFeedback or any supplier has been
        advised of the possibility of such damages and even if the remedy fails
        of its essential purpose.
      </p>
      <p>
        Some states/jurisdictions do not allow the exclusion or limitation of
        incidental or consequential damages, so the above limitation or
        exclusion may not apply to you.
      </p>

      <h2>Severability</h2>
      <p>
        If any provision of this Agreement is held to be unenforceable or
        invalid, such provision will be changed and interpreted to accomplish
        the objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </p>
      <p>
        This Agreement, together with the Privacy Policy and any other legal
        notices published by CentralFeedback on the Services, shall constitute
        the entire agreement between you and CentralFeedback concerning the
        Services. If any provision of this Agreement is deemed invalid by a
        court of competent jurisdiction, the invalidity of such provision shall
        not affect the validity of the remaining provisions of this Agreement,
        which shall remain in full force and effect. No waiver of any term of
        this Agreement shall be deemed a further or continuing waiver of such
        term or any other term, and CentralFeedback's failure to assert any
        right or provision under this Agreement shall not constitute a waiver of
        such right or provision. YOU AND CentralFeedback AGREE THAT ANY CAUSE OF
        ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN
        ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF
        ACTION IS PERMANENTLY BARRED.
      </p>

      <h2>Waiver</h2>
      <p>
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under this Agreement shall not effect a
        party's ability to exercise such right or require such performance at
        any time thereafter nor shall be the waiver of a breach constitute
        waiver of any subsequent breach.
      </p>
      <p>
        o failure to exercise, and no delay in exercising, on the part of either
        party, any right or any power under this Agreement shall operate as a
        waiver of that right or power. Nor shall any single or partial exercise
        of any right or power under this Agreement preclude further exercise of
        that or any other right granted herein. In the event of a conflict
        between this Agreement and any applicable purchase or other terms, the
        terms of this Agreement shall govern.
      </p>

      <h2>Amendments to this Agreement</h2>
      <p>
        CentralFeedback reserves the right, at its sole discretion, to modify or
        replace this Agreement at any time. If a revision is material we will
        provide at least 30 days' notice prior to any new terms taking effect.
        What constitutes a material change will be determined at our sole
        discretion.
      </p>
      <p>
        By continuing to access or use our website after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you are no longer authorized to use
        CentralFeedback.
      </p>

      <h2>Entire Agreement</h2>
      <p>
        The Agreement constitutes the entire agreement between you and
        CentralFeedback regarding your use of the website and supersedes all
        prior and contemporaneous written or oral agreements between you and
        CentralFeedback.
      </p>
      <p>
        You may be subject to additional terms and conditions that apply when
        you use or purchase other CentralFeedback's services, which
        CentralFeedback will provide to you at the time of such use or purchase.
      </p>

      <h2>Updates to Our Terms</h2>
      <p>
        We may change our Service and policies, and we may need to make changes
        to these Terms so that they accurately reflect our Service and policies.
        Unless otherwise required by law, we will notify you (for example,
        through our Service) before we make changes to these Terms and give you
        an opportunity to review them before they go into effect. Then, if you
        continue to use the Service, you will be bound by the updated Terms. If
        you do not want to agree to these or any updated Terms, you can delete
        your account.
      </p>

      <h2>Intellectual Property</h2>
      <p>
        The website and its entire contents, features and functionality
        (including but not limited to all information, software, text, displays,
        images, video and audio, and the design, selection and arrangement
        thereof), are owned by CentralFeedback, its licensors or other providers
        of such material and are protected by and international copyright,
        trademark, patent, trade secret and other intellectual property or
        proprietary rights laws. The material may not be copied, modified,
        reproduced, downloaded or distributed in any way, in whole or in part,
        without the express prior written permission of CentralFeedback, unless
        and except as is expressly provided in these Terms & Conditions. Any
        unauthorized use of the material is prohibited.
      </p>

      <h2>Agreement to Arbitrate</h2>
      <p>
        This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A DISPUTE
        RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE
        ENFORCEMENT OR VALIDITY OF YOUR OR CentralFeedback's INTELLECTUAL
        PROPERTY RIGHTS. The term “dispute” means any dispute, action, or other
        controversy between you and CentralFeedback concerning the Services or
        this agreement, whether in contract, warranty, tort, statute,
        regulation, ordinance, or any other legal or equitable basis. “Dispute”
        will be given the broadest possible meaning allowable under law.
      </p>

      <h2>Notice of Dispute</h2>
      <p>
        In the event of a dispute, you or CentralFeedback must give the other a
        Notice of Dispute, which is a written statement that sets forth the
        name, address, and contact information of the party giving it, the facts
        giving rise to the dispute, and the relief requested. You must send any
        Notice of Dispute via email to: 
        <a
          href="/cdn-cgi/l/email-protection"
          class="__cf_email__"
          data-cfemail="611100140d06090813000f21060c00080d4f020e0c"
        >
          [email&#160;protected]
        </a>
        . CentralFeedback will send any Notice of Dispute to you by mail to your
        address if we have it, or otherwise to your email address. You and
        CentralFeedback will attempt to resolve any dispute through informal
        negotiation within sixty (60) days from the date the Notice of Dispute
        is sent. After sixty (60) days, you or CentralFeedback may commence
        arbitration.
      </p>

      <h2>Binding Arbitration</h2>
      <p>
        If you and CentralFeedback don’t resolve any dispute by informal
        negotiation, any other effort to resolve the dispute will be conducted
        exclusively by binding arbitration as described in this section. You are
        giving up the right to litigate (or participate in as a party or class
        member) all disputes in court before a judge or jury. The dispute shall
        be settled by binding arbitration in accordance with the commercial
        arbitration rules of the American Arbitration Association. Either party
        may seek any interim or preliminary injunctive relief from any court of
        competent jurisdiction, as necessary to protect the party’s rights or
        property pending the completion of arbitration. Any and all legal,
        accounting, and other costs, fees, and expenses incurred by the
        prevailing party shall be borne by the non-prevailing party.
      </p>

      <h2>Submissions and Privacy</h2>
      <p>
        In the event that you submit or post any ideas, creative suggestions,
        designs, photographs, information, advertisements, data or proposals,
        including ideas for new or improved products, services, features,
        technologies or promotions, you expressly agree that such submissions
        will automatically be treated as non-confidential and non-proprietary
        and will become the sole property of CentralFeedback without any
        compensation or credit to you whatsoever. CentralFeedback and its
        affiliates shall have no obligations with respect to such submissions or
        posts and may use the ideas contained in such submissions or posts for
        any purposes in any medium in perpetuity, including, but not limited to,
        developing, manufacturing, and marketing products and services using
        such ideas.
      </p>

      <h2>Promotions</h2>
      <p>
        CentralFeedback may, from time to time, include contests, promotions,
        sweepstakes, or other activities (“Promotions”) that require you to
        submit material or information concerning yourself. Please note that all
        Promotions may be governed by separate rules that may contain certain
        eligibility requirements, such as restrictions as to age and geographic
        location. You are responsible to read all Promotions rules to determine
        whether or not you are eligible to participate. If you enter any
        Promotion, you agree to abide by and to comply with all Promotions
        Rules.
      </p>
      <p>
        Additional terms and conditions may apply to purchases of goods or
        services on or through the Services, which terms and conditions are made
        a part of this Agreement by this reference.
      </p>

      <h2>Typographical Errors</h2>
      <p>
        In the event a product and/or service is listed at an incorrect price or
        with incorrect information due to typographical error, we shall have the
        right to refuse or cancel any orders placed for the product and/or
        service listed at the incorrect price. We shall have the right to refuse
        or cancel any such order whether or not the order has been confirmed and
        your credit card charged. If your credit card has already been charged
        for the purchase and your order is canceled, we shall immediately issue
        a credit to your credit card account or other payment account in the
        amount of the charge.
      </p>

      <h2>Miscellaneous</h2>
      <p>
        If for any reason a court of competent jurisdiction finds any provision
        or portion of these Terms & Conditions to be unenforceable, the
        remainder of these Terms & Conditions will continue in full force and
        effect. Any waiver of any provision of these Terms & Conditions will be
        effective only if in writing and signed by an authorized representative
        of CentralFeedback. CentralFeedback will be entitled to injunctive or
        other equitable relief (without the obligations of posting any bond or
        surety) in the event of any breach or anticipatory breach by you.
        CentralFeedback operates and controls the CentralFeedback Service from
        its offices in . The Service is not intended for distribution to or use
        by any person or entity in any jurisdiction or country where such
        distribution or use would be contrary to law or regulation. Accordingly,
        those persons who choose to access the CentralFeedback Service from
        other locations do so on their own initiative and are solely responsible
        for compliance with local laws, if and to the extent local laws are
        applicable. These Terms & Conditions (which include and incorporate the
        CentralFeedback Privacy Policy) contains the entire understanding, and
        supersedes all prior understandings, between you and CentralFeedback
        concerning its subject matter, and cannot be changed or modified by you.
        The section headings used in this Agreement are for convenience only and
        will not be given any legal import.
      </p>

      <h2>Disclaimer</h2>
      <p>
        CentralFeedback is not responsible for any content, code or any other
        imprecision.
      </p>
      <p>CentralFeedback does not provide warranties or guarantees.</p>
      <p>
        In no event shall CentralFeedback be liable for any special, direct,
        indirect, consequential, or incidental damages or any damages
        whatsoever, whether in an action of contract, negligence or other tort,
        arising out of or in connection with the use of the Service or the
        contents of the Service. The Company reserves the right to make
        additions, deletions, or modifications to the contents on the Service at
        any time without prior notice.
      </p>
      <p>
        The CentralFeedback Service and its contents are provided "as is" and
        "as available" without any warranty or representations of any kind,
        whether express or implied. CentralFeedback is a distributor and not a
        publisher of the content supplied by third parties; as such,
        CentralFeedback exercises no editorial control over such content and
        makes no warranty or representation as to the accuracy, reliability or
        currency of any information, content, service or merchandise provided
        through or accessible via the CentralFeedback Service. Without limiting
        the foregoing, CentralFeedback specifically disclaims all warranties and
        representations in any content transmitted on or in connection with the
        CentralFeedback Service or on sites that may appear as links on the
        CentralFeedback Service, or in the products provided as a part of, or
        otherwise in connection with, the CentralFeedback Service, including
        without limitation any warranties of merchantability, fitness for a
        particular purpose or non-infringement of third party rights. No oral
        advice or written information given by CentralFeedback or any of its
        affiliates, employees, officers, directors, agents, or the like will
        create a warranty. Price and availability information is subject to
        change without notice. Without limiting the foregoing, CentralFeedback
        does not warrant that the CentralFeedback Service will be uninterrupted,
        uncorrupted, timely, or error-free.
      </p>

      <h2>Contact Us</h2>
      <p>Don't hesitate to contact us if you have any questions.</p>
      <ul>
        <li>Via Email: azasoft@paulghiran.com</li>
      </ul>

      <h2>Content Policy</h2>

      <h4>1. Acceptable Use</h4>
      <p>
        By using our platform, you agree to comply with this Content Policy. Our
        goal is to foster a respectful, safe, and inclusive community. You are
        responsible for ensuring that all content you upload, post, or share
        complies with this policy and all applicable laws.
      </p>

      <h4>2. Prohibited Content</h4>
      <p>
        The following types of content are strictly prohibited on our platform:
      </p>

      <p>
        <b>Illegal Content</b>: Content that violates local, national, or
        international laws, including copyrighted material posted without proper
        authorization.
      </p>
      <p>
        <b>Hate Speech and Harassment</b>: Content that promotes violence,
        discrimination, or hatred against individuals or groups based on race,
        ethnicity, religion, gender, sexual orientation, disability, or any
        other protected characteristic.
      </p>
      <p>
        <b>Violent or Graphic Content</b>: Gratuitous depictions of violence,
        gore, or the glorification of harm to individuals or animals.
      </p>
      <p>
        <b>Pornography and Sexual Content</b>: Explicit sexual content, child
        exploitation, or any form of nudity intended to sexualize minors.
      </p>
      <p>
        <b>Misinformation and Fraud</b>: False or misleading information
        intended to deceive others or manipulate opinions, including scams and
        phishing attempts.
      </p>
      <p>
        <b>Malware and Harmful Links</b>: Links to malicious software, viruses,
        or any content designed to harm devices, steal data, or compromise
        security.
      </p>
      <p>
        <b>Impersonation and Deceptive Content</b>: Content impersonating other
        individuals, organizations, or entities with the intent to mislead.
      </p>

      <h4>3. User Responsibilities</h4>
      <p>
        <b>Ownership</b>: You affirm that you own the rights to the content you
        upload or have obtained necessary permissions.
      </p>
      <p>
        <b>Accuracy</b>: Ensure that the content you post is accurate to the
        best of your knowledge.
      </p>
      <p>
        <b>Respect</b>: Treat other users with respect and avoid engaging in
        activities that could harm, defame, or harass others.
      </p>

      <h4>4. Moderation and Reporting</h4>
      <p>
        <b>Manual Moderation</b>: We rely solely on manual moderation for
        reviewing reported content. All reports will be reviewed by our team to
        ensure compliance with this policy.
      </p>
      <p>
        <b>Reporting Mechanism</b>: Users can report content they believe
        violates this policy by sending an email to azasoft@paulghiran.com.
        Please include as much detail as possible about the content in question,
        including links or screenshots if applicable. If a report button is
        present on the page, the users can use the said button instead.
      </p>
      <p>
        <b>No Retaliation</b>: Retaliation against users who report violations
        is strictly prohibited.
      </p>

      <h4>5. Consequences for Policy Violations</h4>
      <p>Violations of this policy may result in:</p>

      <p>
        <b>Content Removal</b>: Immediate removal of content that violates this
        policy.
      </p>
      <p>
        <b>Account Suspension</b>: Temporary or permanent suspension of accounts
        responsible for posting prohibited content.
      </p>
      <p>
        <b>Legal Action</b>: Referral to law enforcement or legal authorities
        for severe or repeated violations, as required.
      </p>

      <h4>6. User Rights</h4>
      <p>
        <b>Appeal</b>: If you believe your content or account was removed in
        error, you may appeal the decision by contacting us at
        azasoft@paulghiran.com.
      </p>
      <p>
        <b>Transparency</b>: We aim to provide clear reasons for content or
        account removal whenever possible.
      </p>

      <h4>7. Amendments</h4>
      <p>
        We reserve the right to update this Content Policy at any time.
        Significant changes will be communicated to users. Continued use of the
        platform after updates indicates acceptance of the revised policy.
      </p>

      <h1>Privacy Policy</h1>
      <p>Updated at 2024-11-25</p>

      <p>
        {" "}
        CentralFeedback (“we,” “our,” or “us”) is committed to protecting your
        privacy. This Privacy Policy explains how your personal information is
        collected, used, and disclosed by CentralFeedback.
      </p>
      <p>
        This Privacy Policy applies to our website, and its associated
        subdomains (collectively, our “Service”) alongside our application,
        CentralFeedback. By accessing or using our Service, you signify that you
        have read, understood, and agree to our collection, storage, use, and
        disclosure of your personal information as described in this Privacy
        Policy and our Terms of Service. 
      </p>

      <h2>Definitions and key terms</h2>
      <p>
        To help explain things as clearly as possible in this Privacy Policy,
        every time any of these terms are referenced, are strictly defined as:
        <ul>
          <li>
            Cookie: small amount of data generated by a website and saved by
            your web browser. It is used to identify your browser, provide
            analytics, remember information about you such as your language
            preference or login information.
          </li>
          <li>
            Company: when this policy mentions “Company,” “we,” “us,” or “our,”
            it refers to Aza Softserv SRL, that is responsible for your
            information under this Privacy Policy.
          </li>
          <li>
            Country: where CentralFeedback or the owners/founders of
            CentralFeedback are based, in this case is Romania
          </li>
          <li>
            Customer: refers to the company, organization or person that signs
            up to use the CentralFeedback Service to manage the relationships
            with your consumers or service users.
          </li>
          <li>
            Device: any internet connected device such as a phone, tablet,
            computer or any other device that can be used to visit
            CentralFeedback and use the services.
          </li>
          <li>
            IP address: Every device connected to the Internet is assigned a
            number known as an Internet protocol (IP) address. These numbers are
            usually assigned in geographic blocks. An IP address can often be
            used to identify the location from which a device is connecting to
            the Internet.
          </li>
          <li>
            Personnel: refers to those individuals who are employed by
            CentralFeedback or are under contract to perform a service on behalf
            of one of the parties.
          </li>
          <li>
            Personal Data: any information that directly, indirectly, or in
            connection with other information — including a personal
            identification number — allows for the identification or
            identifiability of a natural person.
          </li>
          <li>
            Service: refers to the service provided by CentralFeedback as
            described in the relative terms (if available) and on this platform.
          </li>
          <li>
            Third-party service: refers to advertisers, contest sponsors,
            promotional and marketing partners, and others who provide our
            content or whose products or services we think may interest you.
          </li>
          <li>
            Website: CentralFeedback's site, which can be accessed via this URL:
            www.centralfeedback.com
          </li>
          <li>
            You: a person or entity that is registered with CentralFeedback to
            use the Services.
          </li>
        </ul>
      </p>
      <h2>What Information Do We Collect?</h2>
      <p>
        We collect information from you when you visit our website, register on
        our site, place an order, subscribe to our newsletter, respond to a
        survey or fill out a form.
      </p>

      <ul>
        <li>Name / Username</li>
        <li>Phone Numbers</li>
        <li>Email Addresses</li>
        <li>Job Titles</li>
        <li>Password</li>
      </ul>
      <ul></ul>

      <h2>How Do We Use The Information We Collect?</h2>
      <p>
        Any of the information we collect from you may be used in one of the
        following ways:
        <ul>
          <li>
            To personalize your experience (your information helps us to better
            respond to your individual needs)
          </li>
          <li>
            To improve our website (we continually strive to improve our website
            offerings based on the information and feedback we receive from you)
          </li>
          <li>
            To improve customer service (your information helps us to more
            effectively respond to your customer service requests and support
            needs)
          </li>
          <li>To process transactions</li>
          <li>
            To administer a contest, promotion, survey or other site feature
          </li>
          <li>To send periodic emails</li>
        </ul>
      </p>

      <h2>
        When does CentralFeedback use end user information from third parties?
      </h2>
      <p>
        CentralFeedback will collect End User Data necessary to provide the
        CentralFeedback services to our customers.
      </p>
      <p>
        End users may voluntarily provide us with information they have made
        available on social media websites. If you provide us with any such
        information, we may collect publicly available information from the
        social media websites you have indicated. You can control how much of
        your information social media websites make public by visiting these
        websites and changing your privacy settings.
      </p>

      <h2>
        When does CentralFeedback use customer information from third parties?
      </h2>
      <p>
        We receive some information from the third parties when you contact us.
        For example, when you submit your email address to us to show interest
        in becoming a CentralFeedback customer, we receive information from a
        third party that provides automated fraud detection services to
        CentralFeedback. We also occasionally collect information that is made
        publicly available on social media websites. You can control how much of
        your information social media websites make public by visiting these
        websites and changing your privacy settings.
      </p>

      <h2>Do we share the information we collect with third parties?</h2>
      <p>
        We may share the information that we collect, both personal and
        non-personal, with third parties such as advertisers, contest sponsors,
        promotional and marketing partners, and others who provide our content
        or whose products or services we think may interest you. We may also
        share it with our current and future affiliated companies and business
        partners, and if we are involved in a merger, asset sale or other
        business reorganization, we may also share or transfer your personal and
        non-personal information to our successors-in-interest.
      </p>
      <p>
        We may engage trusted third party service providers to perform functions
        and provide services to us, such as hosting and maintaining our servers
        and the website, database storage and management, e-mail management,
        storage marketing, credit card processing, customer service and
        fulfilling orders for products and services you may purchase through the
        website. We will likely share your personal information, and possibly
        some non-personal information, with these third parties to enable them
        to perform these services for us and for you.
      </p>
      <p>
        We may share portions of our log file data, including IP addresses, for
        analytics purposes with third parties such as web analytics partners,
        application developers, and ad networks. If your IP address is shared,
        it may be used to estimate general location and other technographics
        such as connection speed, whether you have visited the website in a
        shared location, and type of the device used to visit the website. They
        may aggregate information about our advertising and what you see on the
        website and then provide auditing, research and reporting for us and our
        advertisers. We may also disclose personal and non-personal information
        about you to government or law enforcement officials or private parties
        as we, in our sole discretion, believe necessary or appropriate in order
        to respond to claims, legal process (including subpoenas), to protect
        our rights and interests or those of a third party, the safety of the
        public or any person, to prevent or stop any illegal, unethical, or
        legally actionable activity, or to otherwise comply with applicable
        court orders, laws, rules and regulations.
      </p>

      <h2>
        Where and when is information collected from customers and end users?
      </h2>
      <p>
        CentralFeedback will collect personal information that you submit to us.
        We may also receive personal information about you from third parties as
        described above.
      </p>

      <h2>How Do We Use Your Email Address?</h2>
      <p>
        By submitting your email address on this website, you agree to receive
        emails from us. You can cancel your participation in any of these email
        lists at any time by clicking on the opt-out link or other unsubscribe
        option that is included in the respective email. We only send emails to
        people who have authorized us to contact them, either directly, or
        through a third party. We do not send unsolicited commercial emails,
        because we hate spam as much as you do. By submitting your email
        address, you also agree to allow us to use your email address for
        customer audience targeting on sites like Facebook, where we display
        custom advertising to specific people who have opted-in to receive
        communications from us. Email addresses submitted only through the order
        processing page will be used for the sole purpose of sending you
        information and updates pertaining to your order. If, however, you have
        provided the same email to us through another method, we may use it for
        any of the purposes stated in this Policy. Note: If at any time you
        would like to unsubscribe from receiving future emails, we include
        detailed unsubscribe instructions at the bottom of each email.
      </p>

      <h2>How Long Do We Keep Your Information?</h2>
      <p>
        We keep your information only so long as we need it to provide
        CentralFeedback to you and fulfill the purposes described in this
        policy. This is also the case for anyone that we share your information
        with and who carries out services on our behalf. When we no longer need
        to use your information and there is no need for us to keep it to comply
        with our legal or regulatory obligations, we’ll either remove it from
        our systems or depersonalize it so that we can't identify you.
      </p>

      <h2>How Do We Protect Your Information?</h2>
      <p>
        We implement a variety of security measures to maintain the safety of
        your personal information when you place an order or enter, submit, or
        access your personal information. We offer the use of a secure server.
        All supplied sensitive/credit information is transmitted via Secure
        Socket Layer (SSL) technology and then encrypted into our Payment
        gateway providers database only to be accessible by those authorized
        with special access rights to such systems, and are required to keep the
        information confidential. After a transaction, your private information
        (credit cards, social security numbers, financials, etc.) is never kept
        on file. We cannot, however, ensure or warrant the absolute security of
        any information you transmit to CentralFeedback or guarantee that your
        information on the Service may not be accessed, disclosed, altered, or
        destroyed by a breach of any of our physical, technical, or managerial
        safeguards.
      </p>

      <h2>Could my information be transferred to other countries?</h2>
      <p>
        CentralFeedback is incorporated in Romania. Information collected via
        our website, through direct interactions with you, or from use of our
        help services may be transferred from time to time to our offices or
        personnel, or to third parties, located throughout the world, and may be
        viewed and hosted anywhere in the world, including countries that may
        not have laws of general applicability regulating the use and transfer
        of such data. To the fullest extent allowed by applicable law, by using
        any of the above, you voluntarily consent to the trans-border transfer
        and hosting of such information.
      </p>

      <h2>
        Is the information collected through the CentralFeedback Service secure?
      </h2>
      <p>
        We take precautions to protect the security of your information. We have
        physical, electronic, and managerial procedures to help safeguard,
        prevent unauthorized access, maintain data security, and correctly use
        your information. However, neither people nor security systems are
        foolproof, including encryption systems. In addition, people can commit
        intentional crimes, make mistakes or fail to follow policies. Therefore,
        while we use reasonable efforts to protect your personal information, we
        cannot guarantee its absolute security. If applicable law imposes any
        non-disclaimable duty to protect your personal information, you agree
        that intentional misconduct will be the standards used to measure our
        compliance with that duty.
      </p>

      <h2>Can I update or correct my information?</h2>
      <p>
        The rights you have to request updates or corrections to the information
        CentralFeedback collects depend on your relationship with
        CentralFeedback. Personnel may update or correct their information as
        detailed in our internal company employment policies.
      </p>
      <p>
        Customers have the right to request the restriction of certain uses and
        disclosures of personally identifiable information as follows. You
        can contact us in order to (1) update or correct your personally
        identifiable information, (2) change your preferences with respect to
        communications and other information you receive from us, or (3) delete
        the personally identifiable information maintained about you on our
        systems (subject to the following paragraph), by cancelling your
        account. Such updates, corrections, changes and deletions will have no
        effect on other information that we maintain, or information that we
        have provided to third parties in accordance with this Privacy Policy
        prior to such update, correction, change or deletion. To protect your
        privacy and security, we may take reasonable steps (such as requesting a
        unique password) to verify your identity before granting you profile
        access or making corrections. You are responsible for maintaining the
        secrecy of your unique password and account information at all times.
      </p>
      <p>
        You should be aware that it is not technologically possible to remove
        each and every record of the information you have provided to us from
        our system. The need to back up our systems to protect information from
        inadvertent loss means that a copy of your information may exist in a
        non-erasable form that will be difficult or impossible for us to locate.
        Promptly after receiving your request, all personal information stored
        in databases we actively use, and other readily searchable media will be
        updated, corrected, changed or deleted, as appropriate, as soon as and
        to the extent reasonably and technically practicable.
      </p>
      <p>
        If you are an end user and wish to update, delete, or receive any
        information we have about you, you may do so by contacting the
        organization of which you are a customer.
      </p>
      <h2>Personnel</h2>
      <p>
        If you are a CentralFeedback worker or applicant, we collect information
        you voluntarily provide to us. We use the information collected for
        Human Resources purposes in order to administer benefits to workers and
        screen applicants.
      </p>
      <p>
        You may contact us in order to (1) update or correct your information,
        (2) change your preferences with respect to communications and other
        information you receive from us, or (3) receive a record of the
        information we have relating to you. Such updates, corrections, changes
        and deletions will have no effect on other information that we maintain,
        or information that we have provided to third parties in accordance with
        this Privacy Policy prior to such update, correction, change or
        deletion.
      </p>
      <h2>Sale of Business</h2>
      <p>
        We reserve the right to transfer information to a third party in the
        event of a sale, merger or other transfer of all or substantially all of
        the assets of CentralFeedback or any of its Corporate Affiliates (as
        defined herein), or that portion of CentralFeedback or any of its
        Corporate Affiliates to which the Service relates, or in the event that
        we discontinue our business or file a petition or have filed against us
        a petition in bankruptcy, reorganization or similar proceeding, provided
        that the third party agrees to adhere to the terms of this Privacy
        Policy.
      </p>

      <h2>Affiliates</h2>
      <p>
        We may disclose information (including personal information) about you
        to our Corporate Affiliates. For purposes of this Privacy Policy,
        "Corporate Affiliate" means any person or entity which directly or
        indirectly controls, is controlled by or is under common control with
        CentralFeedback, whether by ownership or otherwise. Any information
        relating to you that we provide to our Corporate Affiliates will be
        treated by those Corporate Affiliates in accordance with the terms of
        this Privacy Policy.
      </p>

      <h2>Governing Law</h2>
      <p>
        This Privacy Policy is governed by the laws of Romania without regard to
        its conflict of laws provision. You consent to the exclusive
        jurisdiction of the courts in connection with any action or dispute
        arising between the parties under or in connection with this Privacy
        Policy except for those individuals who may have rights to make claims
        under Privacy Shield, or the Swiss-US framework.
      </p>
      <p>
        The laws of Romania, excluding its conflicts of law rules, shall govern
        this Agreement and your use of the website. Your use of the website may
        also be subject to other local, state, national, or international laws.
      </p>
      <p>
        By using CentralFeedback or contacting us directly, you signify your
        acceptance of this Privacy Policy. If you do not agree to this Privacy
        Policy, you should not engage with our website, or use our services.
        Continued use of the website, direct engagement with us, or following
        the posting of changes to this Privacy Policy that do not significantly
        affect the use or disclosure of your personal information will mean that
        you accept those changes.
      </p>

      <h2>Your Consent</h2>
      <p>
        We've updated our Privacy Policy to provide you with complete
        transparency into what is being set when you visit our site and how it's
        being used. By using our website, registering an account, or making a
        purchase, you hereby consent to our Privacy Policy and agree to its
        terms.
      </p>

      <h2>Links to Other Websites</h2>
      <p>
        This Privacy Policy applies only to the Services. The Services may
        contain links to other websites not operated or controlled by
        CentralFeedback. We are not responsible for the content, accuracy or
        opinions expressed in such websites, and such websites are not
        investigated, monitored or checked for accuracy or completeness by us.
        Please remember that when you use a link to go from the Services to
        another website, our Privacy Policy is no longer in effect. Your
        browsing and interaction on any other website, including those that have
        a link on our platform, is subject to that website’s own rules and
        policies. Such third parties may use their own cookies or other methods
        to collect information about you.
      </p>
      <h2>Advertising</h2>
      <p>
        This website may contain third party advertisements and links to third
        party sites. CentralFeedback does not make any representation as to the
        accuracy or suitability of any of the information contained in those
        advertisements or sites and does not accept any responsibility or
        liability for the conduct or content of those advertisements and sites
        and the offerings made by the third parties.
      </p>
      <p>
        Advertising keeps CentralFeedback and many of the websites and services
        you use free of charge. We work hard to make sure that ads are safe,
        unobtrusive, and as relevant as possible.
      </p>
      <p>
        Third party advertisements and links to other sites where goods or
        services are advertised are not endorsements or recommendations by
        CentralFeedback of the third party sites, goods or services.
        CentralFeedback takes no responsibility for the content of any of the
        ads, promises made, or the quality/reliability of the products or
        services offered in all advertisements.
      </p>

      <h2>Cookies for Advertising</h2>
      <p>
        These cookies collect information over time about your online activity
        on the website and other online services to make online advertisements
        more relevant and effective to you. This is known as interest-based
        advertising. They also perform functions like preventing the same ad
        from continuously reappearing and ensuring that ads are properly
        displayed for advertisers. Without cookies, it’s really hard for an
        advertiser to reach its audience, or to know how many ads were shown and
        how many clicks they received.
      </p>
      <h2>Cookies</h2>
      <p>
        CentralFeedback uses "Cookies" to identify the areas of our website that
        you have visited. A Cookie is a small piece of data stored on your
        computer or mobile device by your web browser. We use Cookies to enhance
        the performance and functionality of our website but are non-essential
        to their use. However, without these cookies, certain functionality like
        videos may become unavailable or you would be required to enter your
        login details every time you visit the website as we would not be able
        to remember that you had logged in previously. Most web browsers can be
        set to disable the use of Cookies. However, if you disable Cookies, you
        may not be able to access functionality on our website correctly or at
        all. We never place Personally Identifiable Information in Cookies.
      </p>

      <h2>Blocking and disabling cookies and similar technologies</h2>
      <p>
        Wherever you're located you may also set your browser to block cookies
        and similar technologies, but this action may block our essential
        cookies and prevent our website from functioning properly, and you may
        not be able to fully utilize all of its features and services. You
        should also be aware that you may also lose some saved information (e.g.
        saved login details, site preferences) if you block cookies on your
        browser. Different browsers make different controls available to you.
        Disabling a cookie or category of cookie does not delete the cookie from
        your browser, you will need to do this yourself from within your
        browser, you should visit your browser's help menu for more information.
      </p>
      <h2>Remarketing Services</h2>
      <p>
        We use remarketing services. What Is Remarketing? In digital marketing,
        remarketing (or retargeting) is the practice of serving ads across the
        internet to people who have already visited your website. It allows your
        company to seem like they're “following” people around the internet by
        serving ads on the websites and platforms they use most.
      </p>
      <h2>Payment Details</h2>
      <p>
        In respect to any credit card or other payment processing details you
        have provided us, we commit that this confidential information will be
        stored in the most secure manner possible.
      </p>
      <h2>Kids' Privacy</h2>
      <p>
        We collect information from kids under the age of 13 just to better our
        services. If You are a parent or guardian and You are aware that Your
        child has provided Us with Personal Data without your permission, please
        contact Us. If We become aware that We have collected Personal Data from
        anyone under the age of 13 without verification of parental consent, We
        take steps to remove that information from Our servers.
      </p>
      <h2>Changes To Our Privacy Policy</h2>
      <p>
        We may change our Service and policies, and we may need to make changes
        to this Privacy Policy so that they accurately reflect our Service and
        policies. Unless otherwise required by law, we will notify you (for
        example, through our Service) before we make changes to this Privacy
        Policy and give you an opportunity to review them before they go into
        effect. Then, if you continue to use the Service, you will be bound by
        the updated Privacy Policy. If you do not want to agree to this or any
        updated Privacy Policy, you can delete your account.
      </p>

      <h2>Third-Party Services</h2>
      <p>
        We may display, include or make available third-party content (including
        data, information, applications and other products services) or provide
        links to third-party websites or services ("Third- Party Services").
      </p>
      <p>
        You acknowledge and agree that CentralFeedback shall not be responsible
        for any Third-Party Services, including their accuracy, completeness,
        timeliness, validity, copyright compliance, legality, decency, quality
        or any other aspect thereof. CentralFeedback does not assume and shall
        not have any liability or responsibility to you or any other person or
        entity for any Third-Party Services.
      </p>
      <p>
        Third-Party Services and links thereto are provided solely as a
        convenience to you and you access and use them entirely at your own risk
        and subject to such third parties' terms and conditions.
      </p>
      <h2>Facebook Pixel</h2>
      <p>
        Facebook pixel is an analytics tool that allows you to measure the
        effectiveness of your advertising by understanding the actions people
        take on your website. You can use the pixel to: Make sure your ads are
        shown to the right people. Facebook pixel may collect information from
        your device when you use the service. Facebook pixel collects
        information that is held in accordance with its Privacy Policy
      </p>
      <h2>Tracking Technologies</h2>
      <ul>
        <li>
          Cookies
          <br />
          <br />
          <p>
            We use Cookies to enhance the performance and functionality of our
            platform but are non-essential to their use. However, without these
            cookies, certain functionality like videos may become unavailable or
            you would be required to enter your login details every time you
            visit the platform as we would not be able to remember that you had
            logged in previously.
          </p>
        </li>
        <br />
        <li>
          Local Storage
          <br />
          <br />
          <p>
            Local Storage sometimes known as DOM storage, provides web apps with
            methods and protocols for storing client-side data. Web storage
            supports persistent data storage, similar to cookies but with a
            greatly enhanced capacity and no information stored in the HTTP
            request header.
          </p>
        </li>
        <br />
        <li>
          Sessions
          <br />
          <br />
          <p>
            We use "Sessions" to identify the areas of our website that you have
            visited. A Session is a small piece of data stored on your computer
            or mobile device by your web browser.
          </p>
        </li>
        <br />
      </ul>
      <h2>Information about General Data Protection Regulation (GDPR)</h2>
      <p>
        We may be collecting and using information from you if you are from the
        European Economic Area (EEA), and in this section of our Privacy Policy
        we are going to explain exactly how and why is this data collected, and
        how we maintain this data under protection from being replicated or used
        in the wrong way.
      </p>

      <h2>What is GDPR?</h2>
      <p>
        GDPR is an EU-wide privacy and data protection law that regulates how EU
        residents' data is protected by companies and enhances the control the
        EU residents have, over their personal data.
      </p>
      <p>
        The GDPR is relevant to any globally operating company and not just the
        EU-based businesses and EU residents. Our customers’ data is important
        irrespective of where they are located, which is why we have implemented
        GDPR controls as our baseline standard for all our operations worldwide.
      </p>

      <h2>What is personal data?</h2>
      <p>
        Any data that relates to an identifiable or identified individual. GDPR
        covers a broad spectrum of information that could be used on its own, or
        in combination with other pieces of information, to identify a person.
        Personal data extends beyond a person’s name or email address. Some
        examples include financial information, political opinions, genetic
        data, biometric data, IP addresses, physical address, sexual
        orientation, and ethnicity.
      </p>
      <p>The Data Protection Principles include requirements such as:</p>
      <ul>
        <li>
          Personal data collected must be processed in a fair, legal, and
          transparent way and should only be used in a way that a person would
          reasonably expect.
        </li>
        <li>
          Personal data should only be collected to fulfil a specific purpose
          and it should only be used for that purpose. Organizations must
          specify why they need the personal data when they collect it.
        </li>
        <li>
          Personal data should be held no longer than necessary to fulfil its
          purpose.
        </li>
        <li>
          People covered by the GDPR have the right to access their own personal
          data. They can also request a copy of their data, and that their data
          be updated, deleted, restricted, or moved to another organization.
        </li>
      </ul>

      <h2>Why is GDPR important?</h2>
      <p>
        GDPR adds some new requirements regarding how companies should protect
        individuals' personal data that they collect and process. It also raises
        the stakes for compliance by increasing enforcement and imposing greater
        fines for breach. Beyond these facts it's simply the right thing to do.
        At CentralFeedback we strongly believe that your data privacy is very
        important and we already have solid security and privacy practices in
        place that go beyond the requirements of this new regulation.
      </p>

      <h2>
        Individual Data Subject's Rights - Data Access, Portability and Deletion
      </h2>
      <p>
        We are committed to helping our customers meet the data subject rights
        requirements of GDPR. CentralFeedback processes or stores all personal
        data in fully vetted, DPA compliant vendors. We do store all
        conversation and personal data for up to 6 years unless your account is
        deleted. In which case, we dispose of all data in accordance with our
        Terms of Service and Privacy Policy, but we will not hold it longer than
        60 days.
      </p>
      <p>
        We are aware that if you are working with EU customers, you need to be
        able to provide them with the ability to access, update, retrieve and
        remove personal data. We got you! We've been set up as self service from
        the start and have always given you access to your data and your
        customers data. Our customer support team is here for you to answer any
        questions you might have about working with the API.
      </p>
      <h2>California Residents</h2>
      <p>
        The California Consumer Privacy Act (CCPA) requires us to disclose
        categories of Personal Information we collect and how we use it, the
        categories of sources from whom we collect Personal Information, and the
        third parties with whom we share it, which we have explained above.
      </p>
      <p>
        We are also required to communicate information about rights California
        residents have under California law. You may exercise the following
        rights:
      </p>
      <ul>
        <li>
          Right to Know and Access. You may submit a verifiable request for
          information regarding the: (1) categories of Personal Information we
          collect, use, or share; (2) purposes for which categories of Personal
          Information are collected or used by us; (3) categories of sources
          from which we collect Personal Information; and (4) specific pieces of
          Personal Information we have collected about you.
        </li>
        <li>
          Right to Equal Service. We will not discriminate against you if you
          exercise your privacy rights.
        </li>
        <li>
          Right to Delete. You may submit a verifiable request to close your
          account and we will delete Personal Information about you that we have
          collected.
        </li>
        <li>
          Request that a business that sells a consumer's personal data, not
          sell the consumer's personal data.
        </li>
      </ul>
      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </p>
      <p>We do not sell the Personal Information of our users.</p>
      <p>For more information about these rights, please contact us.</p>
      <h2>California Online Privacy Protection Act (CalOPPA)</h2>
      <p>
        CalOPPA requires us to disclose categories of Personal Information we
        collect and how we use it, the categories of sources from whom we
        collect Personal Information, and the third parties with whom we share
        it, which we have explained above.
      </p>
      <p>CalOPPA users have the following rights:</p>
      <ul>
        <li>
          Right to Know and Access. You may submit a verifiable request for
          information regarding the: (1) categories of Personal Information we
          collect, use, or share; (2) purposes for which categories of Personal
          Information are collected or used by us; (3) categories of sources
          from which we collect Personal Information; and (4) specific pieces of
          Personal Information we have collected about you.
        </li>
        <li>
          Right to Equal Service. We will not discriminate against you if you
          exercise your privacy rights.
        </li>
        <li>
          Right to Delete. You may submit a verifiable request to close your
          account and we will delete Personal Information about you that we have
          collected.
        </li>
        <li>
          Right to request that a business that sells a consumer's personal
          data, not sell the consumer's personal data.
        </li>
      </ul>
      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </p>
      <p>We do not sell the Personal Information of our users.</p>
      <p>For more information about these rights, please contact us.</p>
      <h2>Contact Us</h2>
      <p>Don't hesitate to contact us if you have any questions.</p>
      <ul>
        <li>
          Via Email:{" "}
          <a
            href="/cdn-cgi/l/email-protection"
            class="__cf_email__"
            data-cfemail="f0918a91839f9684b08091859c97989982919ede939f9d"
          >
            [email&#160;protected]
          </a>
        </li>
      </ul>
    </Box>
  </div>
);

export default TOSPage;
