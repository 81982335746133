import { connect } from "react-redux";

import { doLogout } from "reduxCode/actions/auth";

import Logout from "./Logout";

const mapDispatchToProps = dispatch => {
  return {
    doLogout: () => {
      dispatch(doLogout());
    }
  };
};

export default connect(null, mapDispatchToProps)(Logout);
