import APIHandler from "./apiHandler";

import { API_ROUTES } from "./const";

const formApi = {
  getMyFroms: () =>
    APIHandler.get({ url: API_ROUTES.FORM }).then((response) => {
      return response;
    }),
  getFormById: ({ formId }) =>
    APIHandler.get({ url: `${API_ROUTES.FORM}${formId}` }).then((response) => {
      return response[0] ? response[0] : response;
    }),
  patchFormData: (payload) =>
    APIHandler.patch({
      url: API_ROUTES.FORM + payload.formId,
      body: { formData: payload },
    }).then((response) => {
      return response;
    }),
  postFormData: (payload) =>
    APIHandler.post({
      url: API_ROUTES.FORM,
      body: payload,
    }).then((response) => {
      return response;
    }),
};

export default formApi;
