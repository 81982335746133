import React from "react";

import { Navigate } from "react-router-dom";

import Login from "pages/LoginPage";
import Blog from "pages/BlogPage";
import Logout from "pages/LogoutPage";
import Homepage from "pages/HomePage";
import FormsPage from "pages/FormsPage";
import NewFormPage from "pages/NewFormPage";
import Settings from "pages/SettingsPage";
import Register from "pages/RegisterPage";
import Dashboard from "pages/DashboardPage";
import ArticlePage from "pages/ArticlePage";
import ProfilePage from "pages/ProfilePage";
import ResponsesPage from "pages/ResponsesPage";
import ToSPage from "pages/ToSPage.js";

import Button from "components/shared/Button";


/* This file controls both the router and the menu.
  Routes have components & labels for both logged-in and logged-out states.
  For private routes, a Redirect to '/' is recommended
  Paths with no label (for either loggedIn, loggedOut or both) will not get rendered in the menu
*/

const APP_ROUTES = [
  {
    order: 1,
    path: "/login",
    components: {
      loggedIn: () => <Navigate to="/" />,
      loggedOut: Login,
    },
    labels: { loggedIn: "", loggedOut: "Log In" },
  },
  {
    order: 0,
    path: "/terms-and-conditions",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: ToSPage,
      loggedOut: ToSPage,
    },
  },
  {
    order: 2,
    path: "/register",
    components: {
      loggedIn: () => <Navigate to="/" />,
      loggedOut: Register,
    },
    labels: { loggedIn: "", loggedOut: <Button className='get-started' text="Get started" variant="round" /> },
  },
  {
    order: 0,
    path: "<<AUTOREPLACE_SLUG>>",
    components: {
      loggedIn: Settings,
      loggedOut: Login,
    },
    labels: { loggedIn: "My Profile", loggedOut: "" },
  },
  {
    order: 2,
    path: "/settings",
    components: {
      loggedIn: Settings,
      loggedOut: Login,
    },
    labels: { loggedIn: "", loggedOut: "" },
  },
  {
    order: 0,
    path: "/blog/:id",
    components: {
      loggedIn: ArticlePage,
      loggedOut: ArticlePage,
    },
    labels: { loggedIn: "", loggedOut: "" },
  },
  {
    order: 2,
    path: "/blog",
    components: {
      loggedIn: Blog,
      loggedOut: Blog,
    },
    labels: { loggedIn: "", loggedOut: "" },
  },
  {
    order: 9,
    path: "/logout",
    components: {
      loggedOut: () => <Navigate to="/" />,
      loggedIn: Logout,
    },
    labels: { loggedIn: "", loggedOut: "" },
  },
  {
    order: 0,
    path: "/:slug/:formId?",
    components: {
      loggedIn: ProfilePage,
      loggedOut: ProfilePage,
    },
    labels: { loggedIn: "", loggedOut: "" },
  },
  {
    order: 0,
    path: "/",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: FormsPage,
      loggedOut: Homepage,
    },
  },
  {
    order: 0,
    path: "/forms",
    labels: { loggedIn: "My Forms", loggedOut: "" },
    components: {
      loggedIn: FormsPage,
      loggedOut: () => <Navigate to="/" />,
    },
  },
  {
    order: 0,
    path: "/form-builder/:formId?",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: NewFormPage,
      loggedOut: () => <Navigate to="/" />,
    },
  },
  {
    order: 0,
    path: "/form-responses/:formId",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: ResponsesPage,
      loggedOut: () => <Navigate to="/" />,
    },
  },
  {
    order: 1,
    path: "/NotFound",
    labels: { loggedIn: "", loggedOut: "" },
    components: {
      loggedIn: () => (
        <div className="home">
          404, not found.
          <a href="/">Home</a>
        </div>
      ),
      loggedOut: () => (
        <div className="home">
          404, not found.
          <a href="/">Login</a>
        </div>
      ),
    },
  },
];

const sortedRoutes = APP_ROUTES.concat().sort((a, b) => a.order - b.order);

export { sortedRoutes };

export default APP_ROUTES;
