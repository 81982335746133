import React, { useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router";

import Meta from "./Meta";
import Box from "components/shared/Box";
import Form from "components/shared/Form/form";

import responseApi from "api/responseApi"; // Import the response API abstraction
import toastService from "shared/toastService";

import "./feedback.scss";

const getFieldProperties = (field, index) => {
  const commonProps = {
    name: "q" + index,
    label: field.label,
  };
  let props = {};

  switch (field.type) {
    case "short":
      props = { type: "text", ...commonProps };
      return props;

    case "long":
      props = { type: "textarea", ...commonProps };
      return props;

    case "choice":
      props = {
        type: "choice",
        ...commonProps,
        options: field.options.map((string, index) => ({
          value: string,
          label: string,
          ...commonProps,
        })),
      };
      return props;

    default:
      return {
        ...commonProps,
      };
  }
};

const FeedbackForm = ({
  formId,
  description,
  meta,
  questions,
  name,
  cover,
  hideSubmitButton = false,
  responseCount,
}) => {
  const questionsArray = JSON.parse(questions);
  const metaArray = JSON.parse(meta);
  const { formId: URLFormId } = useParams();
  const [submitStatus, setSubmitStatus] = useState("");

  const fields = questionsArray?.map(getFieldProperties) || [];

  const submitForm = async (values) => {
    setSubmitStatus("PENDING");
    const filteredValues = Object.entries(values).filter(
      (values) => values[1] === false || !!values[1] || values[1].length
    );

    const formattedValues = filteredValues.reduce((acc, [key, value]) => {
      const questionIndex = key.replace("q", ""); // Remove the "q" prefix
      const question = questionsArray[questionIndex]?.label; // Match question by index
      if (question) {
        acc[question] = value;
      }
      return acc;
    }, {});

    if (!filteredValues.length) {
      toastService.makeErrorToast({
        title: "Error",
        text: "You cannot submit an empty form.",
      });

      setSubmitStatus("EMPTY");
      return false;
    }

    try {
      const response = await responseApi.submitResponse({
        formId: URLFormId || formId,
        answers: formattedValues,
      });

      setSubmitStatus("SUBMITTED");
    } catch (error) {
      setSubmitStatus("ERROR");
      toastService.makeErrorToast({
        title: "Error!",
        text: "Your response has could not be submitted.",
      });
    }
  };

  return (
    <div className="feedback-form">
      <Box
        size="big"
        title={{ type: "accent", text: name }}
        className="feedback-form__hero"
        cover={cover}
      >
        {description && <p>{description}</p>}
        {metaArray.map((metaObject) => (
          <Meta metaObject={metaObject} />
        ))}

        <Form
          name="response-form"
          fields={fields}
          onSubmit={hideSubmitButton ? false : submitForm}
          disabled={submitStatus == "PENDING"}
        />
        {submitStatus == "SUBMITTED" && (
          <div className="alert alert-success mt-3" role="alert">
            Thank you! Your response has been submitted.
          </div>
        )}
        {submitStatus == "EMPTY" && (
          <div className="alert alert-danger mt-3" role="alert">
            You cannot submit an empty form.
          </div>
        )}
        {submitStatus == "ERROR" && (
          <div className="alert alert-danger mt-3" role="alert">
            Please correct your form and try again.
          </div>
        )}
      </Box>
    </div>
  );
};

FeedbackForm.defaultProps = {
  meta: "[]",
  questions: "[]",
};

export default FeedbackForm;
