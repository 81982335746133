import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Nav from "../Nav";
import Loader from "components/shared/Loader";
import Toaster from "components/shared/Toaster";

import APP_ROUTES from "constants/appRoutes";
import STATUS_TYPES from "constants/statusTypes";
import amplitude from "shared/analytics";

const AppRouter = ({ doGetUserData, userStatus, user, isLoggedIn }) => {
  const [hasCheckedAuthentication, setHasCheckedAuthentication] =
    useState(false);

  useEffect(() => {
    doGetUserData();
  }, [doGetUserData]);

  useEffect(() => {
    if (
      !hasCheckedAuthentication &&
      (userStatus === STATUS_TYPES.LOADED || userStatus === STATUS_TYPES.FAILED)
    ) {
      setHasCheckedAuthentication(true);

      try {
        const identify = amplitude ? new amplitude.Identify() : null;
        if (identify) {
          identify.set("plan", user.plan || "DEFAULT_FREE");
          identify.add("login_count", 1);
          identify.set("email", user.email);
          amplitude.setUserId(`user_${user.id}`);
          amplitude.identify(identify);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [userStatus, hasCheckedAuthentication, user]);

  if (!hasCheckedAuthentication) {
    return (
      <div>
        <div className="min-height-container">
          <Loader
            type={hasCheckedAuthentication ? "hidden" : ""}
            size="full-screen"
          />
        </div>
      </div>
    );
  }

  return (
    <Router>
      <Nav user={user} />
      <div className="min-height-container">
        <Toaster />
        <Routes>
          {APP_ROUTES.map((route) => {
            const Component = isLoggedIn
              ? route.components.loggedIn
              : route.components.loggedOut;
            return (
              <Route
                path={route.path}
                element={<Component />}
                key={route.path}
                {...(route.extraProps || {})}
              />
            );
          })}
        </Routes>
      </div>
      <footer>&copy; Central Feedback</footer>
    </Router>
  );
};

export default AppRouter;
