import React from "react";

import Layout from "components/shared/Layout";

import Blog from "components/Blog";
import Article from "components/Blog/Article";
import Breadcrumbs from "components/shared/Breadcrumbs";

class ArticlePage extends React.PureComponent {
  render() {
    const { id } = this.props.match.params;

    const crumbs = [{
      url: '/blog',
      label: 'Blog'
    }, {
      url: '',
      label: 'This Post'
    }];

    const columns = [
      {
        width: 9,
        component: (
          <div>
            <Breadcrumbs crumbs={crumbs} />
            <Article id={id} />
          </div>
        ),
      },
      {
        width: 3,
        component: <Blog type="small" />,
      },
    ];

    return <Layout columns={columns} className="no-background small" />;
  }
}

export default ArticlePage;
