import React from "react";

import "./layout.scss";

const Layout = ({ columns, className = "" }) => (
  <div className={`main-container container ${className}`}>
    <div className="row">
      {columns.map((col, index) => (
        <div className={`col col-lg-${col.width}`} key={index}>
          {col.component}
        </div>
      ))}
    </div>
  </div>
);

export default Layout;
