import React from "react";
import Layout from "../shared/Layout";
import HomeToggle from "./HomeToggle";
import HomeDisplay from "./HomeDisplay";

import Button from "components/shared/Button";
import screen from "./screen.png"

import paletteSRC from './assets/palette.png';
import shareSRC from './assets/share.png';
import targetSRC from './assets/target.png';
import briefcaseSRC from './assets/briefcase.png';

import "./home.scss";
import "./homeStyle.scss";

class HomeComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { chosenOption: "0" };
  }

  changeOption = (value) => {
    this.setState({ chosenOption: value });
  };

  render() {
    return (
      <div>
        <section id="main-section">
          <div className="container main main-flex">
            <div className="left-h">
              <div className="text">
                <h1>Centralize Your Feedback<br />Empowering Growth</h1>
                <h2>
                  For Trainers, Teachers, and Professionals.
                </h2>
                <Button text="Get Started" className="main-cta" variant="round" action="link" to="/register" />
              </div>
            </div>
          </div>
        </section>

        <section id="how-it-works">
          <div class="container">
            <h3>How it works</h3>
            <div class="features-flex">
              <div class="feature">
                <img src={paletteSRC} />
                <h4>Customizable Forms</h4>
                <p>
                  Create feedback forms tailored to your sessions, courses, or speeches.
                </p>
              </div>
              <div class="feature">
                <img src={briefcaseSRC} />
                <h4>Professional Profile</h4>
                <p>
                  Build a profile that highlights your expertise and expands your network.
                </p>
              </div>
              <div class="feature">
                <img src={targetSRC} />
                <h4>Centralized feedback</h4>
                <p>Access all responses in one organized, easy-to-navigate space.</p>
              </div>
              <div class="feature">
                <img src={shareSRC} />
                <h4>Effortless sharing</h4>
                <p>Share feedback forms instantly with clients and attendees.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HomeComponent;
