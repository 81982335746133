import APIHandler from "./apiHandler";

import { API_ROUTES, API_KEY } from "./const";

const userAPI = {
  getArticles: () =>
    APIHandler.get({ url: API_ROUTES.POSTS + "?key=" + API_KEY }).then(
      (response) => {
        return response;
      }
    ),
  getArticleById: (payload) =>
    APIHandler.get({
      url: API_ROUTES.POSTS + "/" + payload + "?key=" + API_KEY,
    }).then((response) => {
      return response;
    })
};

export default userAPI;
