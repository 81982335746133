import APIHandler from "./apiHandler";
import { API_ROUTES } from "./const";

const responseApi = {
  // Submit a response
  submitResponse: (payload) =>
    APIHandler.post({
      url: API_ROUTES.RESPONSE,
      body: payload,
    }).then((response) => {
      return response;
    }),

  // Get all responses for a specific form
  getResponsesByFormId: ({ formId }) =>
    APIHandler.get({
      url: `${API_ROUTES.RESPONSE}${formId}`,
    }).then((response) => {
      return response;
    }),

  // Mark a response as featured
  makeResponseFeatured: ({ responseId, featuredQuestions }) =>
    APIHandler.patch({
      url: `${API_ROUTES.RESPONSE}${responseId}/feature`,
      body: featuredQuestions,
    }).then((response) => {
      return response;
    }),

  // Get featured responses for a user
  getFeaturedResponses: () =>
    APIHandler.get({
      url: `${API_ROUTES.RESPONSE}featured`,
    }).then((response) => {
      return response;
    }),

  deleteResponse: ({ responseId }) =>
    APIHandler.delete({
      url: `${API_ROUTES.RESPONSE}${responseId}`,
    }).then((response) => {
      return response;
    }),
};

export default responseApi;
