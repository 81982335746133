import React from "react";
import Box from "components/shared/Box";

import "./profile.scss";

const RANKS = ["Free User", "PRO"];

const Profile = ({
  isOwnProfile,
  firstName,
  lastName,
  avatar,
  website,
  publicEmail,
  rank,
  bio,
  className,
}) => (
  <Box className={`profile ${className} ${isOwnProfile ? "profile--own" : ""}`}>
    <div>
      <div className="profile__image">
        <img src={avatar} alt="Profile Image" />
      </div>
    </div>
    <div>
      <h2 className="profile__name">
        {firstName} {lastName}
      </h2>
      <div className={`profile__rank rank-${rank}`}>{RANKS[rank]}</div>
      {RANKS[rank] == "PRO" && (
        <div className="connect-to-me">
          {website &&
            ((website.toLowerCase().includes("http")) ? (
              <a
                href={website}
                rel="nofollow noreferrer"
                className="website"
                target="_blank"
              >
                🌐: {website}
                <br />
              </a>
            ) : (
              <a
                href={`https://${website}`}
                rel="nofollow noreferrer"
                className="website"
                target="_blank"
              >
                🌐: {website}
                <br />
              </a>
            ))}
          {publicEmail && (
            <a
              href={`mailto:${publicEmail}`}
              rel="nofollow noreferrer"
              className="email"
              target="_blank"
            >
              📧: {publicEmail}
              <br />
            </a>
          )}
        </div>
      )}
      {bio && <div className="profile__bio">{bio}</div>}
    </div>
  </Box>
);

export default Profile;
