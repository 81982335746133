import React, { useState } from "react";
import Form from "components/shared/Form/form";
import Box from "components/shared/Box";
import toastService from "shared/toastService";

import penImage from "assets/pen.png";

import "./settingsForm.scss";

const SettingsForm = ({
  user,
  id,
  doPatchUserData,
  avatar,
  email,
  firstName,
  bio,
  slug,
  lastName,
  publicEmail,
  website,
}) => {
  const [imageURL, setImageURL] = useState("");
  const [userData] = useState(user);

  const onSubmit = async (payload) => {
    await doPatchUserData({ formData: payload, userId: id });

    toastService.makeSuccessToast({
      title: "Success!",
      text: "Your settings have been saved.",
    });
  };

  const onSubmitImageUpload = (imageURL) => {
    doPatchUserData({
      formData: { avatar: imageURL },
      userId: id,
    });

    setImageURL(imageURL);
  };

  const onSubmitImage = (payload) => {
    const token = localStorage.getItem("token");
    setImageURL(`https://photo-api.centralfeedback.com/upload/loadish.jpg`);

    const formdata = new FormData();
    formdata.append("photo", payload);

    fetch("https://photo-api.centralfeedback.com/upload", {
      method: "POST",
      headers: {
        authorization: token,
      },
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error("Upload error:", data.error);
          return;
        }
        onSubmitImageUpload(
          `https://photo-api.centralfeedback.com${data.path}`
        );
      })
      .catch((error) => console.error("Error during image upload:", error));
  };

  if (!firstName) return null;

  const IMAGE_FORM_FIELDS = [
    {
      type: "file",
      name: "image",
      label: (
        <div className="settings-form__image-box">
          <img src={penImage} alt="edit-icon" className="pen-img" />
          <div
            className="settings-form__image-side"
            style={{ backgroundImage: `url(${imageURL || avatar})` }}
          ></div>
        </div>
      ),
      errorMessage: "Invalid image",
      onFileUpload: onSubmitImage,
    },
  ];

  const INFO_FORM_FIELDS = [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      errorMessage: "Invalid Name",
      initialValue: firstName,
      width: "half",
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      errorMessage: "Invalid Name",
      initialValue: lastName,
      width: "half",
    },
    {
      type: "email",
      name: "email",
      label: "Email",
      errorMessage: "Invalid email",
      initialValue: email,
    },
    {
      type: "publicEmail",
      name: "publicEmail",
      label: "Public Email",
      explainer:
        "This email is displayed on your profile, if you want people to contact you.",
      errorMessage: "Invalid email",
      initialValue: publicEmail,
    },
    {
      type: "website",
      name: "website",
      label: "Website",
      explainer:
        "Your website on your profile, in case you want people to find you.",
      errorMessage: "Invalid website",
      initialValue: website,
    },
    {
      type: "textarea",
      name: "bio",
      label: "Bio",
      errorMessage: "Invalid bio",
      initialValue: bio,
    },
    {
      type: "text",
      name: "slug",
      explainer: `This will change your user profile's link, like www.centralfeedback.com/short_URL`,
      label: "Short URL",
      errorMessage: "Invalid slug",
      initialValue: slug,
    },
  ];

  return (
    <Box className="settings-form">
      <Form
        name="settings-image-form"
        fields={IMAGE_FORM_FIELDS}
        formName="Personal Information"
        className="settings-form__image-form"
        disabled={!imageURL}
      />
      <Form
        name="settings-form"
        fields={INFO_FORM_FIELDS}
        onSubmit={onSubmit}
        formName=""
        className="settings-form__info-form"
      />
    </Box>
  );
};

export default SettingsForm;
