import React from "react";

import "./box.scss";

const Title = ({ type, text, className }) => {
  switch (type) {
    case "title":
      return (
        <h1 className={`box__title box__title--${type} ${className || ""}`}>
          {text}
        </h1>
      );

    case "accent":
      return (
        <h3 className={`box__title box__title--${type} ${className || ""}`}>
          {text}
        </h3>
      );

    case "custom":
      return (
        <div className={`box__title box__title--${type} ${className || ""}`}>
          {text}
        </div>
      );

    default:
      return (
        <h3 className={`box__title box__title--${type} ${className || ""}`}>
          {text}
        </h3>
      );
  }
};

const Box = ({ children, className = "", title, cover, coverLink, size = "" }) => (
  <div className={`box ${className} ${size}`}>
    {cover && (
      coverLink ? <a href={coverLink}><div
        className="box__cover"
        style={{ backgroundImage: `url(${cover})` }}
      ></div></a> : <div
        className="box__cover"
        style={{ backgroundImage: `url(${cover})` }}
      ></div>
    )}
    {title && <Title {...title} />}

    {children}
  </div>
);

export default Box;
