import React from "react";
import Toast from "./Toast";

const Toaster = ({ doRemoveToast, toasts }) => (
  <ul className="toaster">
    {toasts.map(toast => (
      <Toast
        key={toast.id}
        removeToast={() => doRemoveToast(toast.id)}
        {...toast}
      />
    ))}
  </ul>
);

export default Toaster;
