import React from "react";
import Form from "components/shared/Form/form";

import "./metaForm.scss";

const MetaForm = ({ metaData, onChange, formId }) => {
  const onSubmitImageUpload = (imageURL) => {
    onChange({ ...metaData, cover: imageURL });
  };

  const onSubmitImage = (payload) => {
    var myHeaders = new Headers();

    myHeaders.append("authorization", localStorage.getItem("token"));
    var formdata = new FormData();

    formdata.append("photo", payload);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `https://photo-api.centralfeedback.com/uploadCover/${formId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          return "Error";
        }
        onSubmitImageUpload(
          `https://photo-api.centralfeedback.com${data.path}`
        );
      })
      .catch((error) => console.log("error", error));
  };

  const fields = [
    {
      type: "file",
      name: "cover",
      label: (
        <div>
          <div
            className="meta-form__image-side"
            style={{ backgroundImage: `url(${metaData.cover})` }}
          ></div>
        </div>
      ),
      errorMessage: "Invalidimage",
      onFileUpload: onSubmitImage,
    },
    {
      name: "name",
      label: "Form Title",
      type: "text",
      value: metaData?.name || "",
      placeholder: "Enter the form name",
    },
    {
      name: "expiryDate",
      label: "Expiry Date",
      type: "date",
      initialValue: metaData?.expiryDate || "",
    },
  ];
  return (
    <div className="meta-form">
      <Form name="meta-form" fields={fields} onChange={onChange} formName="" values={metaData} />
    </div>
  );
};

export default MetaForm;
