import ProfilePage from "./Profile";

import { connect } from "react-redux";

import { getUserProfile, } from "reduxCode/actions/user";
import { getUserData, getUserProfile as getUserProfileSelector } from "reduxCode/selectors/user";
import { getErrors } from "reduxCode/selectors/error";


import "./profile.scss";

const mapStateToProps = (state, ownProps) => {
  return {
    user: getUserData(state),
    errors: getErrors(state)["profile"],
    profile: getUserProfileSelector(state, ownProps.id) || {}
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserProfile: (slug) => {
      dispatch(getUserProfile(slug));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
