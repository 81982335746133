import React from "react";
import Login from "components/Login";

class LoginPage extends React.PureComponent {
  render() {
    return <Login />;
  }
}

export default LoginPage;
