import React from "react";
import Form from "components/shared/Form/form";
import Box from "components/shared/Box";

import "./login.scss";

const formFields = [
  {
    type: "email",
    name: "email",
    label: "Email",
    errorMessage: "Invalid Email",
  },
  {
    type: "password",
    name: "password",
    label: "Password",
    errorMessage: "Invalid Password",
  },
];

class Login extends React.PureComponent {
  onSubmit = (payload) => {
    const { doRequestLogin } = this.props;

    doRequestLogin(payload);
  };

  render() {
    const { authError } = this.props;

    return (
      <div className="login-wrapper">
        <Box className="login">
          <h3>
            Welcome to <br />
            Central Feedback
          </h3>
          <p>To get started, please log in</p>
          <Form
            name="login-Form"
            fields={formFields}
            onSubmit={this.onSubmit}
            inputVariant="enclosed"
            formName=""
          />
          {authError?.length > 0 ? (
            <div className="alert alert-danger">
              {authError.length > 1 ? authError : "Login Error."}
            </div>
          ) : (
            ""
          )}
          <a href="/register">No account? Click here to register</a>
        </Box>
      </div>
    );
  }
}

export default Login;
