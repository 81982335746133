import APIHandler from './apiHandler';

import {API_ROUTES} from './const';

const authAPI = {
  doLogin: ({ email, password }) =>
    APIHandler.post({ url: API_ROUTES.AUTH, body: { email, password } }).then(response => {

        return response;
    })
};

export default authAPI;