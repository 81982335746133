import React from "react";
import { Link } from "react-router-dom";

export class NavItem extends React.Component {
  render() {
    const { isLoggedIn, route, user, className, pathname } = this.props;
    const { path } = route;

    const label = isLoggedIn ? route.labels.loggedIn : route.labels.loggedOut;
    const link = path == "<<AUTOREPLACE_SLUG>>" ? `/${user.slug}` : route.path;
    const injectCurrent =
      path == "<<AUTOREPLACE_SLUG>>" && pathname == `/${user.slug}`
        ? "current"
        : "";

    return label ? (
      <li className={`main-nav__item ${className} ${injectCurrent}`}>
        <Link to={link}>{label}</Link>
      </li>
    ) : (
      ""
    );
  }
}

export default NavItem;
