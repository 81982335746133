import React from "react";

import "./button.scss";
import { Button } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import amplitude from "shared/analytics";

const UIButton = ({
  text,
  type = "primary",
  to,
  action,
  onClick,
  className,
  disabled = false,
  size,
  variant
}) => {
  const trackButton = amplitude?.track({
    event_type: "CLICK", properties: {
      path: window.location.pathname
    }
  })
  if (action === "link") {
    return (
      <Link to={to} className="link-button">
        <Button
          type="button"
          className={`button ${type} disabled-${disabled} ${className || ""} ${variant || ''} ${size || ""
            }`}
          onClick={
            onClick
              ? (e) => {
                trackButton();
                e.preventDefault();
                onClick();
              }
              : () => {
                trackButton();
              }
          }>
          {text}
        </Button>

      </Link>
    );
  }
  return (
    <input
      type="button"
      className={`button ${type} disabled-${disabled} ${className || ""} ${variant || ''} ${size || ""
        }`}
      onClick={
        onClick
          ? (e) => {
            e.preventDefault();
            onClick();
          }
          : () => { }
      }
      value={text}
    />
  );
};


export default UIButton;
