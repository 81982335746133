import React from "react";
import OwnSettings from "components/SettingsForm/OwnSettingsForm.jsx";

class DashboardPage extends React.PureComponent {
  render() {
    return <OwnSettings />;
  }
}

export default DashboardPage;
