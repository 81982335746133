import React from "react";

import FormField from "./formField.jsx";

import Button from "components/shared/Button/index.js";

import propTypes from "prop-types";

import "./form.scss";
import amplitude from "shared/analytics.js";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldValues: this.getInitialValues(props.fields),
      validationState: {},
    };
  }

  getInitialValues(fields) {
    const initialFieldValues = {};

    fields.forEach((field) => {
      initialFieldValues[field.name] = field.value || field.initialValue || "";
    });

    return initialFieldValues;
  }

  changeFieldValue = (fieldName, fieldValue) => {
    const fieldValues = this.props.values
      ? this.props.values
      : this.state.fieldValues;

    const newFV = { ...fieldValues, [fieldName]: fieldValue };

    this.setState({ fieldValues: newFV });

    if (this.props.onChange) {
      this.props.onChange(newFV);
    }
  };

  validateForm() {
    const { validationState } = this.state;
    const fieldValues = this.props.values
      ? this.props.values
      : this.state.fieldValues;

    let isValid = true;

    this.props.fields.forEach((field) => {
      const { name, required } = field;
      if (required && !fieldValues[name]) {
        validationState[name] = false;
        isValid = false;
      } else {
        validationState[name] = true;
      }
    });

    this.setState({
      validationState: { ...validationState },
    });

    return isValid;
  }

  submitForm = () => {
    const { onSubmit, name } = this.props;

    amplitude?.track({
      event_type: "SUBMIT_FORM",
      properties: {
        path: window.location.pathname,
        name: name,
      },
    });

    const fieldValues = this.props.values
      ? this.props.values
      : this.state.fieldValues;

    if (this.validateForm()) {
      onSubmit(fieldValues);
    }
  };

  render() {
    const { className, fields, formName, formDescription, disabled, onSubmit } =
      this.props;
    const { validationState } = this.state;

    const fieldValues = this.props.values
      ? this.props.values
      : this.state.fieldValues;

    return (
      <div className={`form ${className}`}>
        {formName && <h4>{formName}</h4>}
        {formDescription && <p>{formDescription}</p>}
        {fields.map((field, i) => (
          <FormField
            key={field.name + "-" + i}
            {...field}
            onChange={(fieldValue) =>
              this.changeFieldValue(field.name, fieldValue)
            }
            isInvalid={validationState[field.name] === false}
            value={fieldValues[field.name]}
            variant={this.props.inputVariant}
          />
        ))}
        {onSubmit && (
          <Button
            onClick={disabled ? () => {} : this.submitForm}
            text="Submit"
            disabled={disabled}
          />
        )}
      </div>
    );
  }
}

Form.defaultProps = {
  className: "",
};

Form.propTypes = {
  className: propTypes.string,
  fields: propTypes.arrayOf(propTypes.object),
  onSubmit: () => {},
};

export default Form;
