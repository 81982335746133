import { ACTION_TYPES } from "constants/actionTypes";
import STATUS_TYPES from "constants/statusTypes";
import authAPI from "api/authApi";

import { setError, unsetError } from "./errors";
import { setStatus as setUserStatus, requestUserData, unsetData as unsetUserData } from "./user";

export function setToken(token) {
  return {
    type: ACTION_TYPES.AUTH.SET_TOKEN,
    payload: token
  };
}

export function unsetToken() {
  return {
    type: ACTION_TYPES.AUTH.UNSET_TOKEN
  };
}

export function setStatus(status) {
  return {
    type: ACTION_TYPES.AUTH.SET_STATUS,
    payload: status
  };
}

export function doLogout() {
  return dispatch => {
    dispatch(unsetToken());
    dispatch(unsetUserData());

    dispatch(setStatus(STATUS_TYPES.INITIAL));
    dispatch(setUserStatus(STATUS_TYPES.INITIAL));
  }
}

export function requestLogin(payload) {
  return dispatch => {
    dispatch(setStatus(STATUS_TYPES.PENDING));
    dispatch(unsetError({ type: "auth"}));

    return authAPI.doLogin(payload).then(
      jsonPayload => {
        dispatch(setToken(jsonPayload.token));
        dispatch(setStatus(STATUS_TYPES.LOADED));

        return dispatch(requestUserData(jsonPayload.token));
      },
      response => {
        dispatch(setStatus(STATUS_TYPES.FAILED));
        dispatch(unsetToken());

        response.then(payload => {
          return dispatch(setError({ type: "auth", errors: [payload.message] }));
        });
      }
    );
  };
}
