import React from "react";
import { useParams } from "react-router";
import Responses from "../components/FeedbackResponses/Responses.js"; // Placeholder component for responses

const ResponsesPage = () => {
  const { formId } = useParams(); // Get formId from the route parameter

  return  <Responses formId={formId} />
};

export default ResponsesPage;
