import React from "react";
import Profile from "components/ProfilePage/";
import { useParams } from "react-router";

const ProfilePage = () => {
  const { formId, slug } = useParams();

  return <Profile slug={slug} formId={formId} />;
};

export default ProfilePage;
