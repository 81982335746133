import React from "react";
import Form from "components/shared/Form/form";

import "../Login/login.scss";

const registerFormFields = [
  {
    type: "text",
    name: "firstName",
    label: "First Name",
    errorMessage: "Invalid name",
    required: true,
  },
  {
    type: "text",
    name: "lastName",
    label: "Last Name",
    errorMessage: "Invalid name",
    required: true,
  },
  {
    type: "email",
    name: "email",
    label: "Email",
    errorMessage: "Invalid Email",
    required: true,
  },
  {
    type: "password",
    name: "password",
    label: "Password",
    errorMessage: "Invalid Password",
    required: true,
  },
  {
    type: "password",
    name: "password2",
    label: "Confirm Password",
    errorMessage: "Passwords Must Match",
    required: true,
  },
  {
    type: "checkbox",
    name: "tac",
    label: "Terms And Conditions",
    required: true,
    explainer: (
      <span>
        By signing up I agree to the{" "}
        <a href="/terms-and-conditions">
          <b>terms and conditions</b>
        </a>
        .
      </span>
    ),
  },
];

class RegisterForm extends React.PureComponent {
  onSubmit = (payload) => {
    const { doPostRegister } = this.props;

    doPostRegister(payload);
  };

  render() {
    const { errors } = this.props;

    return (
      <div className="">
        <h3>
          Welcome to <br />
          Central Feedback
        </h3>
        <p>
          Just a few quick steps, and you’re all set to start collecting
          feedback and growing your network
        </p>
        <Form
          name="register-form"
          fields={registerFormFields}
          onSubmit={this.onSubmit}
          formName=""
        />
        {!!errors?.length && <div className="alert alert-danger">{errors}</div>}
      </div>
    );
  }
}

export default RegisterForm;
