import React, { useState } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import MyForms from "components/FeedbackForms/MyForms.js";
import Button from "components/shared/Button/index.js";
import formApi from "api/formApi.js";
import Box from "components/shared/Box/index.js";
import amplitude from "shared/analytics.js";

import formsRibbon from "assets/forms-ribbon.png";

const FormsPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formName, setFormName] = useState("");

  const handleAddNewForm = () => {
    setModalOpen(true);
  };

  const handleFormSubmit = async () => {
    amplitude?.track({
      event_type: "SUBMIT_FORM",
      properties: {
        path: window.location.pathname,
        name: "new-feedback-form",
      },
    });

    if (!formName) {
      alert("Form name is required!");
      return;
    }

    try {
      const response = await formApi.postFormData({ name: formName });

      if (response.id) {
        window.location.pathname = `/form-builder/${response.id}`;
        setModalOpen(false);
        // Optionally refresh the list of forms or handle the response
      } else {
        alert("Failed to create form. Please try again.");
      }
    } catch (error) {
      console.error("Error creating form:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <div>
      <Modal show={isModalOpen} onHide={() => setModalOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create New Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formName">
              <Form.Label column sm="4">
                Form Name:
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={formName}
                  onChange={(e) => setFormName(e.target.value)}
                  placeholder="Enter form name"
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="secondary"
            onClick={() => setModalOpen(false)}
            text="Cancel"
          />
          <Button type="primary" onClick={handleFormSubmit} text="Submit" />
        </Modal.Footer>
      </Modal>

      <div className="container">
        <div className="my-forms-ribbon-spacer"></div>
        <div className="my-forms-ribbon">
          <h1>My Forms</h1>
          <p>
            Here, you can quickly access, manage, and track the forms you've
            created, making it easier than ever to collect valuable insights.
          </p>
          <img src={formsRibbon} />
        </div>
        <h4>Active Forms</h4>
        <MyForms handleAddNewForm={handleAddNewForm} />
      </div>
    </div>
  );
};

export default FormsPage;
