export const BLOGGER_ID = "313769346268929301";

export const API_URL = "https://api.centralfeedback.com/api/v1";
export const BLOGGER_API_URL = "https://www.googleapis.com/blogger/v3/blogs/" + BLOGGER_ID;

export const API_KEY = "AIzaSyBtqYjmjxalnEgxu3ekN-7PadysR3Wk5kg";

// export const API_URL = "http://46.41.134.242:8080/api/v1";

export const API_ROUTES = {
  AUTH: API_URL + "/auth/login/",
  LISTINGS: API_URL + "/listing/",
  ME: API_URL + "/auth/me/",
  USER: API_URL + "/users/",
  FORM: API_URL + "/forms/",
  PROFILES: API_URL + "/profiles/",
  RESPONSE: API_URL + "/responses/",
  POSTS: BLOGGER_API_URL + "/posts"
};
