import React from "react";
import { Link, Links, useLocation } from "react-router-dom";

import { sortedRoutes } from "constants/appRoutes";
import NavItem from "./NavItem/NavItem";

import logout from "./assets/logout.png";
import settings from "./assets/settings.png";

import "./nav.scss";

const Nav = ({ isLoggedIn, user, className }) => {
  const { pathname } = useLocation();

  let navClass = "";

  if ((pathname === "" || pathname === "/") && !isLoggedIn) {
    navClass = "home-nav";
  }

  if (pathname.includes("/login") || pathname.includes("/register")) {
    navClass = "inverted-nav";
  }

  console.log(user);

  const loggedInKey = isLoggedIn ? "loggedIn" : "loggedOut";

  return (
    <nav className={`main-nav ${navClass || ""}`}>
      <div className="container">
        <div className="main-nav__logo">
          <Link to="/">
            <span className="text-logo">
              Central<b>Feedback</b>
            </span>
          </Link>
        </div>
        <ul className="main-nav__menu">
          {sortedRoutes.map((route) => (
            <>
              {route?.label}
              <NavItem
                className={
                  route?.labels[loggedInKey]
                    ? route?.path == pathname
                      ? "current"
                      : ""
                    : ""
                }
                pathname={pathname}
                key={route.path}
                route={route}
                isLoggedIn={isLoggedIn}
                user={user}
              />
            </>
          ))}
        </ul>
        <label
          className="main-nav__icon"
          style={{ backgroundImage: `url(${user?.avatar})` }}
          for="nav-hidden-input"
        ></label>
        <input type="text" name="focus-hack" id="nav-hidden-input" />
        <label for="nav-hidden-input" className="main-nav__dropdown">
          <Link className="popdown-links" to="/settings">
            <img src={settings} alt="" /> Account Settings
          </Link>
          <Link className="popdown-links" to="/logout">
          <img src={logout} alt="" /> Logout
          </Link>
        </label>
      </div>
    </nav>
  );
};

export default Nav;
