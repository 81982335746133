// Third Party
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { thunk as thunkMiddleware } from 'redux-thunk';

// Reducers
import user from "./user";
import toast from "./toast";
import errors from "./errors";
import listing from "./listings";
import articles from "./articles";
import authentication from "./auth";

// Middleware
import { localStorageMiddleware } from "../middlewares/localStorageMiddleware";

/*
 *
 */

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const combinedReducer = combineReducers({
  user,
  toast,
  errors,
  listing,
  articles,
  authentication
});

const store = createStore(
  combinedReducer,
  composeEnhancer(applyMiddleware(thunkMiddleware, localStorageMiddleware))
);


export default store;
