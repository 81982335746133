import React from "react";

import Box from "components/shared/Box";

import Register from "components/Register";
class RegisterPage extends React.PureComponent {
  render() {
    return (
      <div className="register-wrapper">
        <Box className="register">
          <Register />
        </Box>
      </div>
    );
  }
}

export default RegisterPage;
