import React from "react";

import Layout from "components/shared/Layout";

import Blog from "components/Blog";

const columns = [
  {
    width: 2,
    component: "",
  },
  {
    width: 8,
    component: <Blog />,
  },
  {
    width: 2,
    component: "",
  },
];

class BlogPage extends React.PureComponent {
  render() {
    return <Layout columns={columns} className="no-background"/>;
  }
}

export default BlogPage;
