import { connect } from "react-redux";

import { getUserData } from "reduxCode/selectors/user";
import { getIsLoggedIn } from "reduxCode/selectors/auth";

import Nav from "./nav";

const mapStateToProps = state => {

  return {
    userData: getUserData(state),
    isLoggedIn: getIsLoggedIn(state)
  };
};

export default connect(mapStateToProps)(Nav);
