import React from "react";
import { connect } from "react-redux";

import STATUS_TYPES from "constants/statusTypes";
import { requestUserDataIfToken, patchUserData } from "reduxCode/actions/user";

import { getUserData, getUserDataStatus } from "reduxCode/selectors/user";

import SettingsForm from "./SettingsForm";

class OwnSettingsForm extends React.Component {
  componentDidMount() {
    const { userStatus, user } = this.props;

    if (!user.name && userStatus === STATUS_TYPES.INITIAL)
      this.props.doGetUserData();
  }

  render() {
    const {
      user,
      getUserData,
      getUserDataStatus,
      doGetUserData,
      doPatchUserData,
    } = this.props;

    return (
      <div className="settings-page">
        <h1>Account Settings</h1>
        <p>Update your personal details and preferences all in one place.</p>
        <SettingsForm
          {...user}
          getUserData={getUserData}
          getUserDataStatus={getUserDataStatus}
          doGetUserData={doGetUserData}
          doPatchUserData={doPatchUserData}
          isOwnSettings={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: getUserData(state) || { name: "" },
    userStatus: getUserDataStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetUserData: () => {
      dispatch(requestUserDataIfToken());
    },
    doPatchUserData: (payload) => {
      dispatch(patchUserData(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnSettingsForm);
