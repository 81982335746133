import React from "react";
import AppRouter from "./components/AppRouter";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return <div>
    <AppRouter />
    </div>;
}

export default App;
