import React from "react";

import Box from "components/shared/Box";



class Article extends React.PureComponent {
  componentDidMount() {
    const { fetchArticle, article, id } = this.props;

    if (!article.title) {
      fetchArticle(id);
    }
  }

  render() {
    const { article } = this.props;

    return (
      <Box title={{ type: "plain", text: <h1>{article.title}</h1> }}>
        <article className="article">
          <main>{JSON.stringify(article.content)}</main>
        </article>
      </Box>
    );
  }
}

export default Article;
