import React, { useState, useEffect } from "react";
import WordCloud from "react-wordcloud"; // Import the WordCloud component
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import responseApi from "api/responseApi";
import Box from "components/shared/Box";
import Button from "components/shared/Button";
import Layout from "components/shared/Layout";

import "./responses.scss";

const Responses = () => {
  const { role, slug } = useSelector((state) => state?.user?.data || "");
  const { formId } = useParams(); // Extract formId from the route parameters
  const [formDetails, setFormDetails] = useState(null);
  const [responses, setResponses] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [wordFrequencies, setWordFrequencies] = useState([]);

  const getResponses = async () => {
    try {
      const payload = await responseApi.getResponsesByFormId({ formId });
      setFormDetails(payload); // Form details from the payload
      setResponses(payload.responses || []); // Responses from the payload

      // Generate word frequencies for the word cloud
      const aggregatedText = payload.responses
        .map((response) => {
          const content = JSON.parse(JSON.parse(response.content) || "{}");
          return Object.values(content).join(" "); // Combine all answers
        })
        .join(" ");
      generateWordFrequencies(aggregatedText);
    } catch (error) {
      console.error("Error fetching responses:", error);
      alert("Failed to fetch responses. Please try again later.");
    } finally {
      setIsPending(false);
    }
  };

  const generateWordFrequencies = (text) => {
    const wordCounts = {};
    const words = text.toLowerCase().match(/\b\w+\b/g); // Extract words

    if (words) {
      words.forEach((word) => {
        wordCounts[word] = (wordCounts[word] || 0) + 1;
      });
    }

    const wordCloudData = Object.entries(wordCounts).map(([text, value]) => ({
      text,
      value,
    })).filter(word => word.text.length > 3);

    setWordFrequencies(wordCloudData);
  };

  const deleteResponse = async (responseId) => {
    try {
      await responseApi.deleteResponse({ responseId });
      alert("Response deleted successfully!");
      // Update the responses list
      setResponses((prevResponses) =>
        prevResponses.filter((response) => response.id !== responseId)
      );
    } catch (error) {
      console.error("Error deleting response:", error);
      alert("Failed to delete response. Please try again later.");
    }
  };

  useEffect(() => {
    getResponses();
  }, [formId]);

  if (isPending) return "Loading...";

  // Extract questions from the first response for table headers
  const questions =
    responses.length > 0
      ? Object.keys(JSON.parse(JSON.parse(responses[0].content || "{}")))
      : [];

  const columns = [
    {
      width: 3,
      component: (
        <Box title={{ type: "accent", text: "Form Insights" }}>
          <div className="word-cloud-container">
            <h4 style={{ fontWeight: 400 }}>Word Cloud</h4>
            {wordFrequencies.length > 0 ? (
              <WordCloud words={wordFrequencies} />
            ) : (
              <p>No data to generate word cloud.</p>
            )}
            <h4 style={{ fontWeight: 400 }}>Quick Actions</h4>
            <div style={{ position: 'relative' }}>

              <Link
                to={`/${slug}/${formId}`}
                style={{ color: "#2648c0" }}
              >
                💬 View Form
              </Link>
            </div>
          </div>
        </Box>
      ),
    },
    {
      width: 9,
      component: (
        <div className="responses">
          <Box
            className="form-header"
            cover={formDetails.cover}
            size="big"
            title={{ type: "accent", text: formDetails.name + " - Responses" }}
          >
            {responses.length ? <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    {questions.map((question, index) => (
                      <th key={index}>{question}</th>
                    ))}
                    {role === 2 && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {responses.map((response) => {
                    const answers = JSON.parse(
                      JSON.parse(response.content || "{}")
                    );
                    return (
                      <tr key={response.id}>
                        {questions.map((question, index) => (
                          <td key={index}>{answers[question]}</td>
                        ))}
                        {role == 2 && (
                          <td>
                            <Button
                              className="responses__delete-button"
                              size="small"
                              type="danger"
                              onClick={() => deleteResponse(response.id)}
                              text="X"
                            />
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
              : "No responses available for this form."}
          </Box>
        </div>
      ),
    },
  ];

  return <Layout columns={columns}></Layout>;
};

export default Responses;
