import APIHandler from "./apiHandler";

import { API_ROUTES } from "./const";

const userAPI = {
  fetchUserData: () =>
    APIHandler.get({ url: API_ROUTES.ME }).then((response) => {
      return response;
    }),
  getProfile: ({slug, formId}) =>
    formId
      ? APIHandler.get({ url: API_ROUTES.PROFILES + slug + "/" + formId }).then(
          (response) => {
            return response;
          }
        )
      : APIHandler.get({ url: API_ROUTES.PROFILES + slug }).then((response) => {
          return response;
        }),
  patchUserData: (payload) =>
    APIHandler.patch({
      url: API_ROUTES.USER + payload.userId,
      body: { userData: payload.formData },
    }).then((response) => {
      return response;
    }),
  postUserData: (payload) =>
    APIHandler.post({
      url: API_ROUTES.USER,
      body: payload,
    }).then((response) => {
      return response;
    }),
};

export default userAPI;
