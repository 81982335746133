import React from "react";

import Box from "components/shared/Box";
import Layout from "components/shared/Layout";
import ProfileCard from "components/ProfileCard/ProfileCard";

import { Link } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import FeedbackForm from "../FeedbackForm";

class Profile extends React.PureComponent {
  componentDidMount() {
    const { getUserProfile, slug, formId } = this.props;

    getUserProfile({ slug, formId });
  }

  getColumns() {
    const {
      firstName,
      lastName,
      avatar,
      role,
      rank,
      bio,
      description,
      meta,
      questions,
      slug,
      sponsorship,
      type,
      name,
      otherForms,
      cover,
      formId,
      publicEmail,
      website,
    } = this.props.profile;
    const { user } = this.props;
    const canEditForm = user.slug === slug;

    const columns = [
      {
        width: 3,
        component: (
          <div>
            <ProfileCard
              {...{
                firstName,
                lastName,
                avatar,
                role,
                rank,
                bio,
                publicEmail,
                website,
              }}
            />
            {!canEditForm && (
              <Box
                className={"other-forms"}
                title={{ text: "Latest Forms By " + firstName, type: "small" }}
              >
                {otherForms &&
                  otherForms.map((f, i) => (
                    <a
                      className="other-form"
                      key={f.name + "-" + i}
                      href={`/${slug}/${f.formId}`}
                    >
                      {f.name}
                    </a>
                  ))}
              </Box>
            )}

            {canEditForm && (
              <Box
                size="big"
                title={{ type: "secondary", text: <h3>Form Controls</h3> }}
              >
                <Link to={`/form-builder/${formId}`}>✏️ Edit Form</Link>
                <br />
                <Link to={`/form-responses/${formId}`}>🗨️ View Responses</Link>
                <br />
                <br /> Form QR: <br />
                <QRCodeCanvas
                  style={{ marginTop: "0" }}
                  className="profile__qr"
                  value={`https://centralfeedback.com/${slug}/${formId}`}
                  size={150}
                />
              </Box>
            )}
          </div>
        ),
      },
      {
        width: 9,
        component: (
          <FeedbackForm
            {...{
              formId,
              description,
              meta,
              questions,
              slug,
              sponsorship,
              type,
              name,
              cover,
            }}
          ></FeedbackForm>
        ),
      },
    ];

    return columns;
  }

  getColumns404() {
    const columns = [
      {
        width: 3,
        component: (
          <ProfileCard
            {...{
              firstName: "Not",
              lastName: "Found",
              avatar: "/404.webp",
              role: "",
              rank: "404",
              bio: "Sorry, we can't find the page you are looking for.",
            }}
          />
        ),
      },
      {
        width: 9,
        component: (
          <FeedbackForm
            hideSubmitButton={true}
            {...{
              description:
                "We're sorry, it seems the page you're looking for doesn't exist.",
              meta: "[]",
              questions: "[]",
              type: "",
              name: "Missing page",
            }}
          ></FeedbackForm>
        ),
      },
    ];

    return columns;
  }

  render() {
    const { errors } = this.props;

    if (errors && errors[0] && errors[0].code == 404) {
      return <Layout columns={this.getColumns404()}></Layout>;
    }

    return <Layout columns={this.getColumns()}></Layout>;
  }
}

Profile.defaultProps = {
  firstName: "Not Found",
};

export default Profile;
