import React, { useState, useEffect } from "react";
import formApi from "api/formApi";
import Box from "components/shared/Box";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "components/shared/Button";

import "./myForms.scss";

const MyForms = ({ isExpanded = true, handleAddNewForm = () => { } }) => {
  const slug = useSelector((state) => state?.user?.data?.slug || "");

  const [myForms, setMyForms] = useState([]);
  const [isPending, setIsPending] = useState(true);

  const getForms = async () => {
    const forms = await formApi.getMyFroms();
    setMyForms(forms);
    setIsPending(false);
  };

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(
      () => alert("URL copied to clipboard!"),
      (err) => alert("Failed to copy URL")
    );
  };

  useEffect(() => {
    getForms();
  }, []);

  if (isPending) return "Loading";

  if (!myForms.length) return "No forms at the moment";

  return (
    <div className="my-forms">
      <div className="row">

        <div className="col-md-4">
          <div className={"button-form"}>
            <Button
              className="add-button"
              type="primary"
              text="+ Add new form"
              onClick={handleAddNewForm}
            ></Button>
          </div>
        </div>
        {myForms.map((form) => (
          <div className={isExpanded ? "col-md-4" : "small-form"} key={form.formId}>
            <Link
              to={`/${slug}/${form.formId}`}>
              <Box
                className={"card form-card"}
                cover={form.cover || " "}
                size="small"
                coverLink={`/${slug}/${form.formId}`}
              ><h6>{form.name}</h6>
                <br />
                <div className="container-fluid">
                  <div className="row">
                    <div className={isExpanded ? "col-md-6 no-padding" : "full-button"}>
                      {isExpanded && <><Link
                        to={`/form-builder/${form.formId}`}
                        className="form-action"
                      >
                        ✏️ Edit Form
                      </Link>
                        <br /></>}

                      <Link
                        to={`/form-responses/${form.formId}`}
                        className="form-action"
                      >
                        🗨️ {form.responseCount || '0'} Responses
                      </Link>
                      <br />
                    </div>
                    <div className="col-md-6" style={{ marginTop: '8px' }}>
                      {isExpanded && <Button
                        size="small"
                        type="secondary"
                        className="form-url"
                        onClick={() =>
                          copyToClipboard(
                            `${window.location.origin}/${slug}/${form.formId}`
                          )
                        }
                        text="Copy Form URL"
                      />}
                    </div>
                  </div>
                </div>
              </Box></Link>
          </div>
        ))
        }
      </div >
    </div >
  );
};

export default MyForms;
