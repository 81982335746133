import React, { useEffect, useState } from "react";
import Button from "components/shared/Button";
import FormField from "components/shared/Form/formField";
import Layout from "components/shared/Layout";
import MetaForm from "./MetaForm"; // Importing the MetaForm component

import "./myForms.scss";
import formApi from "api/formApi";
import Box from "components/shared/Box";
import ToastService from "shared/toastService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import amplitude from "shared/analytics";

const FormBuilderWithMeta = ({ formId }) => {
  const [fields, setFields] = useState([]);
  const [fieldType, setFieldType] = useState("short");
  const [label, setLabel] = useState("");
  const [options, setOptions] = useState("");
  const [metaData, setMetaData] = useState({}); // State for metadata
  const [isPending, setIsPending] = useState(true);
  const [formData, setFormData] = useState({});
  const [pendingSecondSubmit, setPendingSecondSubmit] = useState(false);
  const myUserId = useSelector((state) => state?.user?.data?.id || "");
  const navigate = useNavigate();

  const getFormData = async () => {
    try {
      const form = await formApi.getFormById({ formId });

      if (form.owner != myUserId) {
        navigate("/forms");
        ToastService.makeErrorToast({
          title: "Form Error",
          text: `You cannot edit this form.`,
        });
      }
      setFormData(form);

      if (form.owner) setFields(JSON.parse(form.questions));
      setMetaData({ ...form });
      setIsPending(false);
    } catch (e) {
      ToastService.makeErrorToast({
        title: "Form Error",
        text: `We couldn't find your form.`,
      });
      setIsPending(false);
    }
  };

  useEffect(() => {
    getFormData();
  }, []);

  const handleAddField = () => {
    const newField = { type: fieldType, label };

    amplitude?.track({
      event_type: "ADD_FORM_FIELD",
      properties: {
        path: window.location.pathname,
        fieldType: fieldType,
      },
    });

    if (fieldType === "choice") {
      newField.options = options.split(",").map((option) => option.trim());
    }
    setFields([...fields, newField]);
    setLabel("");
    setOptions("");
  };

  const handleDeleteField = (index) => {
    setFields(fields.filter((_, i) => i !== index));
  };

  const handleMetaSubmit = (meta) => {
    setMetaData(meta); // Update metadata when submitted
  };

  const handleFinalSubmit = async () => {
    const finalFormData = { ...metaData, questions: JSON.stringify(fields) };

    if (label?.length > 0 && pendingSecondSubmit == false) {
      setPendingSecondSubmit(true);

      return true;
    }

    if (pendingSecondSubmit) {
      setPendingSecondSubmit(false);
      setLabel("");
    }

    await formApi.patchFormData({
      formId,
      ...finalFormData,
    });

    ToastService.makeSuccessToast({
      title: "Form Saved",
      text: "Your settings have been saved!",
    });
  };

  const onMetaChange = (values) => {
    if (values.cover?.includes("fakepath")) return false;
    console.log("ASD", values);
    setMetaData({ ...values });
  };

  if (isPending) {
    return "Loading";
  }

  const columns = [
    {
      width: 4,
      component: (
        <div className="box">
          <h2>Form Builder</h2>
          <hr />

          <MetaForm
            metaData={metaData}
            onChange={onMetaChange}
            formId={formId}
          />
          <hr />
          <h5>Add a Field</h5>
          <div className="form-builder__add-field">
            <label>
              Field Type:
              <select
                value={fieldType}
                onChange={(e) => setFieldType(e.target.value)}
              >
                <option value="short">Short Text</option>
                <option value="long">Long Text</option>
                <option value="choice">Dropdown</option>
                <option value="email">email</option>
              </select>
            </label>
            <br />
            <label>
              Label:
              <input
                type="text"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </label>
            <br />
            {fieldType === "choice" && (
              <label>
                Options (comma-separated):
                <input
                  type="text"
                  value={options}
                  onChange={(e) => setOptions(e.target.value)}
                />
              </label>
            )}
            <div className="add-field">
              <Button
                text="Add Field"
                onClick={handleAddField}
                style={{ padding: "5px 10px" }}
                type="secondary"
                size="small"
                disabled={!label}
              ></Button>
              <br />
            </div>
            <hr />

            <div>
              <Button
                text="Save Form"
                onClick={handleFinalSubmit}
                style={{ padding: "5px 10px" }}
                type="primary"
                disabled={!fields.length || !metaData.name.length}
              ></Button>
              {pendingSecondSubmit ? (
                <div className="alert alert-warning mt-3" role="alert">
                  Your last field hasn't been added to the form. Proceed anyway?
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      width: 8,
      component: (
        <div>
          <h3>Preview</h3>
          <hr />
          <Box
            className="preview-box"
            size="big"
            cover={metaData.cover}
            title={{ type: "accent", text: metaData.name + "" }}
          >
            {fields.map((field, index) => (
              <div
                key={`field-${index}`}
                className="delete-wrapper"
                style={{ position: "relative" }}
              >
                <FormField {...field} onChange={() => {}} />
                <Button
                  onClick={() => handleDeleteField(index)}
                  type="danger"
                  text="Delete"
                  size="small"
                  className={"form-builder__delete"}
                >
                  Delete
                </Button>
              </div>
            ))}
          </Box>
        </div>
      ),
    },
  ];

  return (
    <Layout columns={columns} className="form-builder">
      <div style={{ textAlign: "center", marginTop: "20px" }}></div>
    </Layout>
  );
};

export default FormBuilderWithMeta;
